<app-header></app-header>
<section id="contact" class="contact section-bg">

    <div class="container">
        <div class="section-title">
            <h2>RECUPERAR CONTRASEÑA</h2>
            <!--
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, perferendis hic obcaecati quam unde
                corrupti minima rerum iusto eos similique nihil vel itaque, cumque culpa quis? Ullam itaque consequatur
                aliquam?</p>
            -->
        </div>
    </div>
    <div class="container-fluid">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-6 align-items-stretch contact-form-wrap">

                <form [formGroup]="form" (submit)="onRecover()" class="php-email-form">
                    <div class="row">
                        <div class="form-group mt-3 mt-md-0">
                            <label for="email">Correo electrónico</label>
                            <input type="email" class="form-control" formControlName="email" id="email"
                                placeholder="Correo electrónico">
                            <div *ngIf="form.get('email').errors?.required && form.get('email').touched"
                                class="alert alert-danger" role="alert">
                                Por favor ingrese su correo electrónico.
                            </div>
                            <div *ngIf="form.get('email').errors?.email && form.get('email').touched"
                                class="alert alert-danger" role="alert">
                                Correo electrónico inválido.
                            </div>
                        </div>
                        <div class="recaptcha mt-4">
                            <re-captcha #captchaElem (resolved) = "resolved($event)" siteKey = "{{recaptchaCode}}"></re-captcha>
                        </div>

                    </div>
                    <!-- Submit buttons -->
                    <div *ngIf="!this.isLoading" class="text-center">
                        <div class="text-center mt-4"><button type="submit" [disabled]="form.invalid">Recuperar</button></div>
                    </div>
                    <div *ngIf="this.isLoading" class="text-center mt-4">
                        <button type="submit" [disabled]="form.invalid" style="width: 166px;">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Cargando...
                        </button>
                    </div>
                    
                    <!-- SUCCESS AND ERROR -->
                    <div *ngIf="success" class="alert alert-success mt-3" role="alert">
                        Se ha enviado un mensaje a su correo.
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                        Ha ocurrido un error. Intente más tarde.
                    </div>


                </form>
            </div>

        </div>

    </div>
</section><!-- End Contact Us Section -->
<app-footer></app-footer>