<app-header></app-header>


<section id="services" class="services">
  <div class="container">

    <div class="section-title">
      <h2>Nuestros servicios</h2>
      <p>En <strong class="text-orange fw-bold">Octaedro</strong> nos tomamos en serio tu futuro, por eso, nuestra
        misión empresarial es diseñar modelos de orientación vocacional por medio de una plataforma interactiva que
        permita la adecuada toma de decisiones en estudiantes de colegio, universitarios y empresas interesadas en el
        sector laboral y educativo.</p>
    </div>


    <div class="row icon-box" id = "orientacion">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-person-lines-fill"></i></div>
        <h4 class="title">Test de orientación vocacional</h4>
        <p class="description">Nuestro test usa la metodología de Holland para que descifres cuál es tu perfil
          vocacional, y nuestro de software usará la inteligencia artificial mostrándote la relación directa con tu
          perfil vocacional, la oferta académica y laboral del país. Tus resultados se quedarán para tu reserva en el
          panel de administración una vez que accedas con tu usuario a nuestra plataforma.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsCheck" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>

    
    <div class="row icon-box mt-4" id = "acompañamiento">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-chat-dots"></i></div>
        <h4 class="title">Acompañamiento psicológico</h4>
        <p class="description">
          Tenemos a un grupo de profesionales que te darán una asistencia personalizada para discutir tus resultados
          vocacionales. El acompañamiento con nuestros asesores estará mediado en la búsqueda de tus habilidades socio
          emocionales y capacidades según el perfil profesional que anhelas.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsTerapia" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>

   
    <div class="row icon-box mt-4" id = "curaduria">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-columns-gap"></i></div>
        <h4 class="title">Curaduría laboral y académica</h4>
        <p class="description">
          Sabemos que la tercera y cuarta revolución industrial ha traído consigo nuevas formas de trabajo; la
          automatización, la inteligencia artificial y la robótica va cambiar los procesos y las formas en las que
          ejercíamos cualquier función, por ello, queremos ofrecerte una asesoría que defina las cualidades analíticas
          e
          investigativas que te permitan adelantarte al cambio, revisando en detalle cuáles son las posibilidades que
          le
          quedan a tu sector académico de crecer o morir en el largo plazo para que así no llegues a futuras
          frustraciones porque tu carrera no es útil ni relevante para el medio en el que intentas aplicarla.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsMariposa" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>

    
    <div class="row icon-box mt-4" id = "etica">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-hurricane" style="position: relative; top:3px;"></i></div>
        <h4 class="title">Ética profesional y existencialismo</h4>
        <p class="description">
          Entendemos que la forma en que se toman las decisiones profesionales está motivada por causas que no siempre
          son racionales, el alto grado de emocionalidad e influencia de factores externos suelen ser en ocasiones
          incomprensibles, llevándonos a situaciones de miedo y angustia que nos impiden acercarnos con fluidez a la
          experimentación real de la libertad. Por eso, hemos elaborado un test filosófico que ahonde en la
          construcción
          de tu esencia y la búsqueda de tu ser para que tus objetivos estén motivados por el encuentro de tus
          virtudes
          más profundas y el acceso a esa parte de tu conciencia que expresa autenticidad.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsSer" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>


    <div class="row icon-box mt-4" id = "entrevista">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-people-fill"></i></div>
        <h4 class="title">Simulacro de entrevista</h4>
        <p class="description">
          Entendemos que la importancia de alcanzar tus metas está en cumplir con todos los filtros, por eso contamos
          con un equipo que te dará las estrategias y herramientas necesarias para acceder a ese gran sueño que es
          entrar a la universidad.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsConversacion" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>


    <div class="row icon-box mt-4" id = "conferencia">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-chat-square-quote" style="position: relative; top:4px;"></i></div>
        <h4 class="title">Conferencias y charlas</h4>
        <p class="description">
          Asistimos a tu colegio o empresa para explicar cuáles son los retos, demandas académicas y profesionales que
          necesitas aprender para diseñar tu futuro profesional y económico.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsExpositor" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>


    <div class="row icon-box mt-4" id = "otros">
      <div class="col-12 col-lg-9">
        <div class="icon"><i class="bi bi-diagram-3"></i></div>
        <h4 class="title">Otros servicios</h4>
        <p class="description">
          En ocasiones buscamos un acompañamiento más a la medida de nuestros objetivos, teniendo en cuenta esto,
          hemos
          diseñado un test de orientación profesional que se ajuste a tu medida, en donde cada respuesta tiene el
          adecuado acompañamiento para que paso a paso descifres con claridad a dónde quieres llegar.
        </p>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-center">
        <div class="animation-check">
          <ng-lottie [options]="optionsRobot" containerClass="animation-check"></ng-lottie>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>