<app-header></app-header>
<section id="contact" class="contact section-bg">

    <div class="container">
        <div class="section-title">
            <h2>CAMBIAR CONTRASEÑA</h2>
            <!--
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta, perferendis hic obcaecati quam unde
                corrupti minima rerum iusto eos similique nihil vel itaque, cumque culpa quis? Ullam itaque consequatur
                aliquam?</p>
            -->
        </div>
    </div>

    <div class="container-fluid">
        <div class="row d-flex justify-content-center">
            <div class="col-lg-6 align-items-stretch contact-form-wrap">
                <form [formGroup]="form" (submit)="onChangePassword()" class="php-email-form">
                    <div class="row">
                        <!--
                        <div class="form-group mt-3">
                            <label for="oldPassword">Contraseña antigua</label>
                            <input type="password" class="form-control" formControlName="oldPassword"
                                placeholder="Contraseña antigua">
                            <div *ngIf="form.get('oldPassword').errors?.required && form.get('oldPassword').touched"
                                class="alert alert-danger" role="alert">
                                Por favor ingrese su contraseña.
                            </div>
                        </div>
                        -->

                        <div class="form-group mt-3">
                            <label for="newPassword">Contraseña nueva</label>
                            <input type="password" class="form-control" formControlName="newPassword"
                                placeholder="Contraseña nueva">
                            <div *ngIf="form.get('newPassword').errors?.required && form.get('newPassword').touched"
                                class="alert alert-danger" role="alert">
                                Por favor ingrese su nueva contraseña.
                            </div>
                            <div *ngIf="form.get('newPassword').errors?.minlength && form.get('newPassword').touched"
                                class="alert alert-danger" role="alert">
                                La contraseña debe contener al menos {{
                                form.get('newPassword').errors?.minlength.requiredLength }} caracteres.
                            </div>
                        </div>


                        <div class="form-group mt-3">
                            <label for="confirmPassword">Repita contraseña nueva</label>
                            <input type="password" class="form-control" formControlName="confirmPassword"
                                placeholder="Contraseña nueva" compare="password">
                            <div *ngIf="form.get('confirmPassword').errors?.required && form.get('confirmPassword').touched"
                                class="alert alert-danger mb-0" role="alert">
                                Por favor ingrese nuevamente su contraseña.
                            </div>
                            <div *ngIf="form.errors?.notSame && form.get('confirmPassword').touched"
                                class="alert alert-danger" role="alert">
                                Las contraseñas no coinciden.
                            </div>
                        </div>




                    </div>
                    <div class="recaptcha mt-4">
                        <re-captcha #captchaElem (resolved)="resolved($event)" siteKey="{{recaptchaCode}}"></re-captcha>
                    </div>

                    <!-- Submit buttons -->
                    <div *ngIf="!this.isLoading" class="text-center">
                        <div class="text-center mt-4"><button type="submit" [disabled]="form.invalid">Cambiar</button>
                        </div>
                    </div>
                    <div *ngIf="this.isLoading" class="text-center mt-4">
                        <button type="submit" [disabled]="form.invalid" style="width: 166px;">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Cargando...
                        </button>
                    </div>

                    <!-- SUCCESS AND ERROR -->
                    <div *ngIf="success" class="alert alert-success mt-3" role="alert">
                        Su contraseña ha sido actualizada.
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3" role="alert">
                        Ha ocurrido un error. Intente más tarde.
                    </div>


                </form>
            </div>

        </div>

    </div>
</section><!-- End Contact Us Section -->
<app-footer></app-footer>