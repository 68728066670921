<app-header></app-header>

<section class="container">
    <div class="px-4 py-4 shadow">

        <div class="row d-flex justify-content-center">
            <div class="animation-logo2 mt-5">
                <ng-lottie [options]="optionsLogo2" containerClass="animation-logo2"></ng-lottie>
            </div>
        </div>

        <h1 class="mb-5 text-orange text-center">Términos y Condiciones</h1>
        <p style="text-align: justify !important;">
            De conformidad con lo previsto en la ''Ley estatutaria 1581 de 2012'', y sus ''Decretos reglamentarios''
            1377 de 2013 y 1074 de 2015 capitulo 25, autorizo con consentimiento previo, expreso, informado, voluntario
            e inequívoco como Titular de mis datos a OCTAEDRO Diseñamos Futuro S.A.S. para que realice el respectivo
            tratamiento de mis datos personales, respuestas de formulario, imágenes y/o vídeos, y en especial aquellos
            definidos como datos sensibles, para que sean incorporados en una base de datos y sean tratados con la
            finalidad académica, comercial, investigativa, científica, de proyección social, cultural, recreativa,
            deportiva, de interés general nacional e internacional, siendo publicados en redes sociales y otros medios
            de comunicación que utilice OCTAEDRO, para las actividades que se enmarquen dentro del objetivo social,
            académico e institucional, además, autorizo que mis datos personales sean transferidos a terceras entidades
            tanto nacionales como internacionales cuya finalidad sea la investigación, academia, cultural o social.
            <br><br>
            De igual modo, declaro que no me encuentro obligado a autorizar el tratamiento de mis datos personales
            sensibles y que he sido informado que puedo ejercer los derechos de acceso, corrección, supresión,
            revocación o reclamo por infracción sobre mis datos mediante escrito dirigido a OCTAEDRO Diseñamos Futuro
            S.A.S. en la dirección de correo electrónico <strong>info@octaedro.com.co</strong> indicando en el Asunto el derecho que
            deseo ejercer, con base en la política de protección de datos, a la cual podré tener acceso en la página web
            http://www.octaedro.com.co El presente documento establece los Términos y Condiciones del consentimiento
            informado para la aplicación de la prueba de Orientación Vocacional, por lo que el usuario que realiza la
            aplicación del instrumento debe realizarlo de acuerdo con los acuerdos aquí señalados. Al acceder o utilizar
            la prueba de Orientación Vocacional, el usuario acepta plenamente y sin reservas el acuerdo con los términos
            del consentimiento informado. Estos Términos consisten en un acuerdo de confidencialidad y colaboración
            entre el usuario y OCTAEDRO, que abarca el acceso, uso, obtención de resultados y recolección de datos para
            fines investigativos. En caso de no estar de acuerdo con estos Términos y Condiciones es necesario
            abstenerse de utilizar la herramienta. Al hacer uso de esta prueba, usted confirma que comprende y está de
            acuerdo con:
            <br><br>
            <strong class = "text-orange">1. Confidencialidad:</strong> La información recogida por la herramienta se encuentra amparada por el código ético y
            deontológico del Colegio Colombiano de Psicólogos, el artículo 3 de la ley 1098 de 2006 y lo señalado en el
            artículo 2°, numeral 5° de la ley 1090 de 2006.
            <br><br>
            <strong class = "text-orange">2. Octaedro adquiere los derechos de uso para la prueba de orientación vocacional</strong>, exceptuado la información
            considerada de utilidad pública o de libre acceso como por ejemplo programas académicos, profesiones,
            enfoques, créditos académicos, líneas de investigación. El contenido expuesto en Octaedro es confidencial
            dado que responde a un trabajo intelectual de total exclusividad y por eso no debe usar con propósitos
            diferentes a los señalados en los servicios ofrecidos por Octaedro. Cualquier violación a los principios o
            lineamientos da causa previa para ejecutar acciones de investigación con fines legales.
            <br><br>
            <strong class = "text-orange">3. Restricciones de uso:</strong> El uso de la prueba de Orientación Vocacional, está permitido solamente para
            personas mayores de (18) años, o menores entre 13 y 17 años con el debido consentimiento y autorización
            expresa de sus acudientes, padres o tutores.
            <br><br>
            <strong class = "text-orange">4. Podemos terminar o suspender de manera permanente o temporal tu acceso al servicio</strong> sin previo aviso y
            responsabilidad por cualquier razón, incluso si a nuestra sola determinación tú violas alguna disposición de
            estos Términos o cualquier ley o regulación aplicable. Puedes descontinuar el uso y solicitar cancelar tu
            cuenta y / o cualquier servicio en cualquier momento. Sin perjuicio de lo contrario en lo que antecede, con
            respecto a las suscripciones renovadas automáticamente a los servicios pagados, dichas suscripciones se
            suspenderán solo al vencimiento del período correspondiente por el que ya has realizado el pago.
            <br><br>
            <strong class = "text-orange">5. Nos reservamos el derecho de modificar estos términos</strong> de vez en cuando a nuestra entera discreción. Por
            lo tanto, debes revisar estas páginas periódicamente. Cuando cambiemos los Términos de una manera material,
            te notificaremos que se han realizado cambios importantes en los Términos. El uso continuado de la página
            web o nuestro servicio después de dicho cambio constituye tu aceptación de los nuevos Términos. Si no
            aceptas alguno de estos términos o cualquier versión futura de los Términos, no uses o accedas (o continúes
            accediendo) a la página web o al servicio.
            <br><br>
            Cualquier otra sugerencia, contacto o comentario al respecto de la prueba pueden ser remitidos al correo
            electrónico <strong>info@octaedro.com.co</strong> adjuntando el asunto Comentarios y sugerencias Prueba de Orientación
            Vocacional.


        </p>
    </div>
</section>


<app-footer></app-footer>