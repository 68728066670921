<!-- ======= Cta Section ======= -->
<section class="home" id="home">
  <div class="container">
    <div class="row d-flex justify-content-center">

      <div class="animation-logo2 mt-5">
        <ng-lottie [options]="optionsLogo2" containerClass="animation-logo2"></ng-lottie>
      </div>

      <div class="col-10 text-center">

        <h2 class="animate__animated animate__fadeInUp">Te damos la bienvenida a <span
            class="text-orange">Octaedro</span></h2>
        <p class="animate__animated animate__fadeInUp">Nuestro test de orientación profesional utiliza modelos
          de
          aprendizaje de máquina para ayudarte a tomar la mejor decisión profesional. Te ofrecemos una
          plataforma
          interactiva para ayudarte a construir tu futuro.</p>
        <a href="#test" class="btn-get-started animate__animated animate__fadeInUp scrollto"
          style="font-size: 20px;">Desbloquea tu futuro</a>

      </div>

    </div>


  </div>
</section><!-- End Cta Section -->

<app-header></app-header>

<main id="main">

  <!-- ======= Info Box Section ======= -->
  <section class="info-box py-0" id = "quienes-somos">
    <div class="container-fluid">

      <div class="row">

        <!-- order-2 order-lg-1-->
        <div class="col-lg-7 d-flex flex-column justify-content-center align-items-stretch">

          <div class="content">
            <h3 class="text-center my-4 mt-lg-0">Te explicamos <strong class="text-orange">Octaedro</strong> en tres
              pasos</h3>
            <p class="">
              Octaedro es una empresa dedicada a ofrecer soluciones de ética profesional y empresarial usando análisis de datos y creando 
              instrumentos de alta tecnología para dar valor y contenido a estudiantes de colegios, universidades y empresas de 
              diferentes sectores. Nuestro objetivo es el fortalecimiento de prácticas y principios que contribuyan al descubrimiento
              de propósitos y creación de sentidos. Acompáñanos en esta apuesta para que juntos diseñemos futuro.
            </p>
          </div>

          <div class="accordion-list mt-3">
            <ul>
              <li>
                <a data-bs-toggle="collapse" class="collapse" data-bs-target="#accordion-list-1"><span>01</span>¿Cómo
                  inicio? <i class="bi bi-caret-down icon-show"></i><i class="bi bi-caret-down icon-close"></i></a>
                <div id="accordion-list-1" class="collapse show" data-bs-parent=".accordion-list">
                  <p>
                    En <strong class="text-orange">Octaedro</strong> analizamos tu personalidad, habilidades, talentos,
                    objetivos académicos y proyecciones laborales. Resolvemos cualquier incertidumbre existencial para
                    asegurarnos que tu proyecto de vida estará acorde con la decisión que vas a tomar y lo hagas de
                    forma auténtica.
                  </p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" class="collapsed"><span>02</span>¿Qué
                  resultados obtendré? <i class="bi bi-caret-down icon-show"></i><i
                    class="bi bi-caret-down icon-close"></i></a>
                <div id="accordion-list-2" class="collapse" data-bs-parent=".accordion-list">
                  <p>
                    Nuestra plataforma te presentará múltiples beneficios al corregir la asimetría de la información
                    académica y laboral a través del funcionamiento en BIGDATA e inteligencia artificial, la cual te
                    garantizará un mayor beneficio con una inversión de tiempo menor para cualquier estudiante y padre
                    de familia.
                  </p>
                </div>
              </li>

              <li>
                <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" class="collapsed"><span>03</span>¿Tengo
                  acceso a una plataforma interactiva? <i class="bi bi-caret-down icon-show"></i><i
                    class="bi bi-caret-down icon-close"></i></a>
                <div id="accordion-list-3" class="collapse" data-bs-parent=".accordion-list">
                  <p>
                    Obtendrás un reporte gráfico personalizado de tus resultados que te dará los instrumentos necesarios
                    para realizar un seguimiento sobre la elección tomada en el corto y largo plazo.
                  </p>
                </div>
              </li>

            </ul>
          </div>

        </div>

        <!--
        <div class="col-lg-5 align-items-stretch order-1 order-lg-2 img"
          style="background-image: url(../assets/img/info-box.jpg);">&nbsp;</div>
        
         align-items-stretch order-1 order-lg-2 img
        -->
        <div class="col-lg-5 animation-dashboard mb-5 mb-lg-0">
          <ng-lottie [options]="optionsDashboard" containerClass="animation-dashboard"></ng-lottie>
        </div>

      </div>


    </div>
  </section><!-- End Info Box Section -->

  <!-- ======= About Us Section ======= -->
  <section id="test" class="about section-gradient">
    <div class="container">



      <div class="row d-flex align-items-center">

        <div class="col-lg-6 animation-women">
          <ng-lottie [options]="optionsWomen" containerClass="animation-women"></ng-lottie>
        </div>

        <div class="col-lg-6 p-2  h-100">
          <div class="section-title" style="margin-bottom: 0px !important;">
            <h2 class="text-white">DESBLOQUEA TU FUTURO</h2>
          </div>
          <p class="text-center text-white">
            Hemos querido llevar los test de orientación profesional más allá de lo pensado, por eso nuestro test de
            orientación profesional está diseñado con herramientas de machine learning e inteligencia artificial que
            automatiza ​tu información y te construye unos patrones de resultados con muchas variables que te acercarán
            de forma más rápida e interactiva para que tomes la mejor decisión profesional.
            <br><br>
            Los algoritmos de programación que implementamos en nuestro test usan la metodología de perfilamiento del
            tradicional test de Holland para establecer un perfil vocacional de manera automática con respuestas
            integradas a una cantidad considerable de datos para darte más información con menos procesos.
          </p>
          <div class="text-center">
            <a class="mt-3 btn-start" href="/test">Empieza tu test</a>
          </div>
        </div>


      </div>




    </div>
  </section> <!-- End About Us Section -->

  <!-- ======= Counts Section ======= -->
  <!--  
  <section class="counts">
    <div class="container">

      <div class="row no-gutters">

        <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
          <div class="count-box">
            <i class="bi bi-emoji-smile"></i>
            <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
            <p><strong>Happy Clients</strong> consequuntur quae qui deca rode</p>
            <a href="#">Find out more &raquo;</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
          <div class="count-box">
            <i class="bi bi-journal-richtext"></i>
            <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
            <p><strong>Projects</strong> adipisci atque cum quia aut numquam delectus</p>
            <a href="#">Find out more &raquo;</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
          <div class="count-box">
            <i class="bi bi-headset"></i>
            <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter"></span>
            <p><strong>Hours Of Support</strong> aut commodi quaerat. Aliquam ratione</p>
            <a href="#">Find out more &raquo;</a>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
          <div class="count-box">
            <i class="bi bi-people"></i>
            <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
            <p><strong>Hard Workers</strong> rerum asperiores dolor molestiae doloribu</p>
            <a href="#">Find out more &raquo;</a>
          </div>
        </div>

      </div>

    </div>
  </section> -->
  <!-- End Counts Section -->

  <!-- ======= Our Services Section ======= -->

  <section id="services" class="services">
    <div class="container">

      <div class="section-title">
        <h2>Nuestros servicios</h2>
        <p>En <strong class="text-orange fw-bold">Octaedro</strong> nos tomamos en serio tu futuro, por eso, nuestra
          misión empresarial es diseñar modelos de orientación vocacional por medio de una plataforma interactiva que
          permita la adecuada toma de decisiones en estudiantes de colegio, universitarios y empresas interesadas en el
          sector laboral y educativo.</p>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-person-lines-fill"></i></div>
            <h4 class="title"><a href="/services#orientacion">Test de orientación profesional</a></h4>
            <p class="description">Desbloquea tu futuro, no dejes pasar la oportunidad de cambiarlo todo.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-chat-dots"></i></div>
            <h4 class="title"><a href="/services#acompañamiento">Acompañamiento psicológico</a></h4>
            <p class="description">Nuestra orientación psicológica te dará la mejor respuesta según tus cualidades.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-columns-gap"></i></div>
            <h4 class="title"><a href="/services#curaduria">Curaduría laboral y académica</a></h4>
            <p class="description">Tus objetivos profesionales son muy importantes como para dejarlos al azar. Tu
              propuesta de valor laboral a tu alcance. </p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-hurricane"></i></div>
            <h4 class="title"><a href="/services#etica">Ética profesional y existencialismo</a></h4>
            <p class="description">Una crisis existencial es un llamado de atención a una búsqueda esencial que se ha
              dejado de lado. No lo sigas aplazando, tenemos tus respuestas.</p>
          </div>
        </div>

      </div>

      <div class="row mt-4 d-flex justify-content-center">
        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-people-fill"></i></div>
            <h4 class="title"><a href="/services#entrevista">Simulacro de entrevista</a></h4>
            <p class="description">Que la entrevista a la universidad no sea un obstáculo, tenemos las estrategias que
              buscas.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-chat-square-quote"></i></div>
            <h4 class="title"><a href="/services#conferencia">Conferencias y charlas</a></h4>
            <p class="description">Vamos a donde tengas incertidumbres y te damos las claves para entender las
              revoluciones y cambios que se avecinan. No te quedes esperando el cambio, lidéralo. </p>
          </div>
        </div>

        <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-diagram-3"></i></div>
            <h4 class="title"><a href="/services#otros">Otros servicios</a></h4>
            <p class="description">Test personalizado de orientación profesional, ética y responsabilidad social
              corporativa, acompañamiento de estudios en el exterior.</p>
          </div>
        </div>
      </div>


      <div class="text-center mt-2 mt-lg-5">
        <a class="btn-more" href="/services">Explorar</a>
      </div>

    </div>
  </section>


  <!-- ======= Our Services Section ======= -->
  <!--
  <section id="services" class="services">
    <div class="container">

      <div class="section-title">
        <h2>Nuestros servicios</h2>
        <p>En <strong class="text-orange fw-bold">Octaedro</strong> nos tomamos en serio tu futuro, por eso, nuestra
          misión empresarial es diseñar modelos de orientación vocacional por medio de una plataforma interactiva que
          permita la adecuada toma de decisiones en estudiantes de colegio, universitarios y empresas interesadas en el
          sector laboral y educativo.</p>
      </div>

 
      <div class="row icon-box">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-person-lines-fill"></i></div>
          <h4 class="title"><a href="/services">Test de orientación vocacional</a></h4>
          <p class="description">Nuestro test usa la metodología de Holland para que descifres cuál es tu perfil
            vocacional, y nuestro de software usará la inteligencia artificial mostrándote la relación directa con tu
            perfil vocacional, la oferta académica y laboral del país. Tus resultados se quedarán para tu reserva en el
            panel de administración una vez que accedas con tu usuario a nuestra plataforma.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsCheck" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>

      
      <div class="row icon-box mt-4">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-chat-dots"></i></div>
          <h4 class="title"><a href="/services">Acompañamiento psicológico</a></h4>
          <p class="description">
            Tenemos a un grupo de profesionales que te darán una asistencia personalizada para discutir tus resultados
            vocacionales. El acompañamiento con nuestros asesores estará mediado en la búsqueda de tus habilidades socio
            emocionales y capacidades según el perfil profesional que anhelas.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsTerapia" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>

     
      <div class="row icon-box mt-4">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-columns-gap"></i></div>
          <h4 class="title"><a href="/services">Curaduría laboral y académica</a></h4>
          <p class="description">
            Sabemos que la tercera y cuarta revolución industrial ha traído consigo nuevas formas de trabajo; la
            automatización, la inteligencia artificial y la robótica va cambiar los procesos y las formas en las que
            ejercíamos cualquier función, por ello, queremos ofrecerte una asesoría que defina las cualidades analíticas
            e
            investigativas que te permitan adelantarte al cambio, revisando en detalle cuáles son las posibilidades que
            le
            quedan a tu sector académico de crecer o morir en el largo plazo para que así no llegues a futuras
            frustraciones porque tu carrera no es útil ni relevante para el medio en el que intentas aplicarla.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsMariposa" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>

      
      <div class="row icon-box mt-4">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-hurricane" style="position: relative; top:3px;"></i></div>
          <h4 class="title"><a href="/services">Ética profesional y existencialismo</a></h4>
          <p class="description">
            Entendemos que la forma en que se toman las decisiones profesionales está motivada por causas que no siempre
            son racionales, el alto grado de emocionalidad e influencia de factores externos suelen ser en ocasiones
            incomprensibles, llevándonos a situaciones de miedo y angustia que nos impiden acercarnos con fluidez a la
            experimentación real de la libertad. Por eso, hemos elaborado un test filosófico que ahonde en la
            construcción
            de tu esencia y la búsqueda de tu ser para que tus objetivos estén motivados por el encuentro de tus
            virtudes
            más profundas y el acceso a esa parte de tu conciencia que expresa autenticidad.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsSer" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>


      <div class="row icon-box mt-4">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-people-fill"></i></div>
          <h4 class="title"><a href="/services">Simulacro de entrevista</a></h4>
          <p class="description">
            Entendemos que la importancia de alcanzar tus metas está en cumplir con todos los filtros, por eso contamos
            con un equipo que te dará las estrategias y herramientas necesarias para acceder a ese gran sueño que es
            entrar a la universidad.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsConversacion" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>


      <div class="row icon-box mt-4">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-chat-square-quote" style="position: relative; top:4px;"></i></div>
          <h4 class="title"><a href="/services">Conferencias y charlas</a></h4>
          <p class="description">
            Asistimos a tu colegio o empresa para explicar cuáles son los retos, demandas académicas y profesionales que
            necesitas aprender para diseñar tu futuro profesional y económico.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsExpositor" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>


      <div class="row icon-box mt-4">
        <div class="col-12 col-lg-9">
          <div class="icon"><i class="bi bi-diagram-3"></i></div>
          <h4 class="title"><a href="/services">Otros servicios</a></h4>
          <p class="description">
            En ocasiones buscamos un acompañamiento más a la medida de nuestros objetivos, teniendo en cuenta esto,
            hemos
            diseñado un test de orientación profesional que se ajuste a tu medida, en donde cada respuesta tiene el
            adecuado acompañamiento para que paso a paso descifres con claridad a dónde quieres llegar.
          </p>
        </div>
        <div class="col-12 col-lg-3 d-flex justify-content-center">
          <div class="animation-check">
            <ng-lottie [options]="optionsRobot" containerClass="animation-check"></ng-lottie>
          </div>
        </div>
      </div>
    </div>
  </section>
-->
  <!-- ======= Cta Section ======= -->
  <section class="cta">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-12 col-lg-8 animation-aviones">
          <ng-lottie [options]="optionsAviones" containerClass="animation-aviones"></ng-lottie>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-center">
            <h3>Construye tu futuro</h3>
            <p>¿Qué estas esperando? En <strong style="color: #e8c95c;">Octaedro</strong> te damos la brújula y te
              acompañamos
              para que inicies el camino que lleve a tus metas profesionales.</p>
            <a class="cta-btn" href="#contact">Contáctanos</a>
          </div>
        </div>
      </div>


    </div>
  </section><!-- End Cta Section -->

  <!-- ======= More Services Section ======= -->
  <section class="more-services section-bg" id="portfolio">
    <div class="container">

      <div class="section-title">
        <h2>Diseñamos futuro</h2>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="card">
            <!--
            <img src="../assets/img/more-service-1.jpg" class="card-img-top" alt="...">
            -->

            <iframe class="video-fluid" src="https://www.youtube.com/embed/uxDCLj7VIg8" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            <div class="card-body">
              <h5 class="card-title"><a href=""> <span class="text-orange">Sueños</span> </a></h5>
              <p class="card-text text-center">Vive tus sueños y no sueñes tu vida. Diseña tu futuro.</p>
              <!--
              <a href="#" class="btn">Explore more</a>
              -->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="card">
            <!--
            <img src="../assets/img/more-service-2.jpg" class="card-img-top" alt="...">
            -->

            <iframe class="video-fluid" src="https://www.youtube.com/embed/NTAVtQ7RqPI" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            <div class="card-body">
              <h5 class="card-title"><a href=""><span class="text-orange">Realidades</span></a></h5>
              <p class="card-text text-center">La realidad son todos esos motivos que rechazas por miedo o por
                resignación. Detrás del velo no hay nada, lo que ves, es lo que puedes hacer. </p>
              <!--
              <a href="#" class="btn">Explore more</a>
              -->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="card">
            <!--
            <img src="../assets/img/more-service-3.jpg" class="card-img-top" alt="...">
            src="https://www.youtube.com/embed/KhPPg1JxtxM?autoplay=1&muted=1&loop=1&playlist=KhPPg1JxtxM"
            -->
            <iframe class="video-fluid" src="https://www.youtube.com/embed/KhPPg1JxtxM" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            <div class="card-body">
              <h5 class="card-title"><a href=""><span class="text-orange">Expectativas</span></a></h5>
              <p class="card-text text-center">La libertad es el insumo más importante de tu vida, cada instante deja a
                su paso una línea de posibilidades en las que tú estás a punto de ser.</p>
              <!--
              <a href="#" class="btn">Explore more</a>
              -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End More Services Section -->


  <!-- ======= Our Portfolio Section ======= -->
  <!--
  <section id="portfolio" class="portfolio section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Nuestro Portafolio</h2>
        <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
      </div>

      <div class="row">
        <div class="col-lg-12 d-flex justify-content-center">
          <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Card</li>
            <li data-filter=".filter-web">Web</li>
          </ul>
        </div>
      </div>

      <div class="row portfolio-container">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>App 1</h4>
              <p>App</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>App 2</h4>
              <p>App</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 2</h4>
              <p>Card</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Web 2</h4>
              <p>Web</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 2"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>App 3</h4>
              <p>App</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 1</h4>
              <p>Card</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 1"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Card 3</h4>
              <p>Card</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web">
          <div class="portfolio-wrap">
            <img src="../assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
            <div class="portfolio-info">
              <h4>Web 3</h4>
              <p>Web</p>
            </div>
            <div class="portfolio-links">
              <a href="../assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
              <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>-->
  <!-- End Our Portfolio Section -->

  <!-- ======= Our Team Section ======= -->
  <!--
  <section id="team" class="team">
    <div class="container">

      <div class="section-title">
        <h2>Nuestro equipo</h2>
        <p>Somos un equipo de profesionales en filosofía, gestión y desarrollo humano, urbanismo, psicología y gerencia
          educativa con más de 10 años de experiencia. Lorem ipsum dolor sit, amet consectetur adipisicing elit.
          Molestiae non perspiciatis deleniti, necessitatibus magnam tenetur.</p>
      </div>

      <div class="row">

 
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="member">
            <img src="../assets/img/octaedro/new_julio.png" class="img-fluid" alt="">
            <div class="member-info">
              <div class="member-info-content">
                <h4>Julio López</h4>
                <span>Fundador y creador de Octaedro</span>
              </div>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6" data-wow-delay="0.1s">
          <div class="member">
            <img src="../assets/img/octaedro/new_edwin.png" class="img-fluid" alt="">
            <div class="member-info">
              <div class="member-info-content">
                <h4>Edwin Barbosa</h4>
                <span>Cofundador de Octaedro</span>
              </div>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-4 col-lg-4 col-md-6" data-wow-delay="0.2s">
          <div class="member">
            <img src="../assets/img/octaedro/new_lina.png" class="img-fluid" alt="">
            <div class="member-info">
              <div class="member-info-content">
                <h4>Lina Guavita</h4>
                <span>Psicóloga</span>
              </div>
              <div class="social">
                <a href=""><i class="bi bi-twitter"></i></a>
                <a href=""><i class="bi bi-facebook"></i></a>
                <a href=""><i class="bi bi-instagram"></i></a>
                <a href=""><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>



      </div>

    </div>
  </section>-->

  <!-- ======= More Services Section ======= -->
  <section class="more-services" id="equipo">
    <div class="container">

      <div class="section-title">
        <h2>Nuestro equipo</h2>
        <p>Nuestro equipo de profesionales cuenta con una diversidad de conocimientos que pueden aportarte lo necesario
          para cumplir tus objetivos y metas académicas.</p>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="card">
            <!--
            <img src="../assets/img/more-service-1.jpg" class="card-img-top" alt="...">
            -->

            <img src="../assets/img/octaedro/new_julio.png" class="img-fluid" alt="">
            <div class="card-body section-bg">
              <h5 class="card-title"><a href=""> <span class="text-orange">Julio López</span> </a></h5>
              <p class="card-text text-center">
                Fundador y creador de Octaedro<br>
                Licenciado en filosofía y literatura<br>
                Magíster en Estudios y Gestión para el Desarrollo Humano<br>
                Especialista en Estado, Política pública y Desarrollo<br>
              </p>
              <!--
              <a href="#" class="btn">Explore more</a>
              -->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="card">
            <!--
            <img src="../assets/img/more-service-2.jpg" class="card-img-top" alt="...">
            -->

            <img src="../assets/img/octaedro/new_edwin.png" class="img-fluid" alt="">
            <div class="card-body section-bg">
              <h5 class="card-title"><a href=""><span class="text-orange">Edwin Barbosa</span></a></h5>
              <p class="card-text text-center">
                Cofundador de Octaedro<br>
                Urbanista<br>
                Magister en Diseño de la Información y Visualización de Datos<br>
              </p>
              <!--
              <a href="#" class="btn">Explore more</a>
              -->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0">
          <div class="card">
            <!--
            <img src="../assets/img/more-service-3.jpg" class="card-img-top" alt="...">
            src="https://www.youtube.com/embed/KhPPg1JxtxM?autoplay=1&muted=1&loop=1&playlist=KhPPg1JxtxM"
            -->
            <img src="../assets/img/octaedro/new_lina.png" class="img-fluid" alt="">
            <div class="card-body section-bg">
              <h5 class="card-title"><a href=""><span class="text-orange">Lina Guavita</span></a></h5>
              <p class="card-text text-center">
                Psicóloga<br>
                Magister en Dirección de Instituciones Educativas<br>
                Especialista en Gerencia Educativa
              </p>
              <!--
              <a href="#" class="btn">Explore more</a>
              -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End More Services Section -->



  <app-contact></app-contact>

</main><!-- End #main -->

<app-footer></app-footer>
<a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
    class="bi bi-arrow-up-short"></i></a>

<div class="cookie shadow-lg" *ngIf = "getCookies() === null">

  <div class="row">
    <div class="col-md-4 col-sm-12 button-fixed">
      <div class="p-3 pb-4 bg-custom text-white">
        <div class="row">
          <div class="col-10">
            <h2>Aceptar Cookies</h2>
          </div>
          <div class="col-2 text-center">
            <i class="fas fa-times"></i>
          </div>
        </div>
        <p>
          Nuestro sitio web utiliza cookies para mejorar la experiencia de usuario dentro del portal, acepta para continuar. Al dar click en el botón "Aceptar Cookies", usted acepta el uso de cookies.
          Para saber más sobre nuestra política de cookies, consulte <a class = "text-info" href = "/cookies" target = "_blanck">aquí</a>. 
        </p>
        <button type="button" class="btn btn-danger w-100" (click) = "acceptCookies()">Aceptar Cookies</button>
      </div>
    </div>
  </div>
</div>