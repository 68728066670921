<div class="d-sm-flex align-items-center justify-content-between mt-5">
    <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53;">Buscador</h1>
</div>
<!--
<p class="my-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis deserunt corrupti optio placeat
    recusandae sint totam dolor, modi, distinctio eos ipsum consectetur quis tempore nobis earum amet rerum. Illum
o asperiores repellendus as    laboriosam eveniet eum eligendi odipernatur voluptate ab. Rerum dolor hic sed ex
    debitis praesentium nostrum ipsam a unde!</p>
-->


<div class="px-4 pt-2 pb-4 mt-4 bg-white border rounded shadow">
    <!-- Formularios -->
    <section style="padding: 0px;" class="mt-3">
        <div class="row">
            <p class="alert alert-danger mb-4" *ngIf="error">
                Ha ocurrido un error. Intente más tarde.
            </p>
            <form class="form" [formGroup]="form">
                <div class="row">
                    <!-- Dimensión -->
                    <div class="form-group col-12 col-lg-4">
                        <h5 class="text-orange">Dimensión</h5>
                        <select class="form-select" formControlName="dimension" aria-label="Default select example">
                            <option [value]="0" selected></option>
                            <option *ngFor="let dim of dimensiones; let i = index" [value]="i + 1">{{ dim }}</option>
                        </select>
                        <!-- Dimensiones seleccionadas -->
                        <div class="mt-3">
                            <ul class="lista d-flex flex-column">
                                <li class="p-2 btn btn-outline-orange mt-1"
                                    *ngFor="let dim of dimensiones_seleccionadas; let i = index"
                                    (click)="onRemove('dimensiones', i)">
                                    {{ dim }}
                                    <i class="bi bi-x-circle"></i>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Departamento y ciudad -->
                    <div class="form-group col-12 col-lg-4 mt-3 mt-lg-0">
                        <h5 class="text-orange">Departamento y ciudad</h5>
                        <select class="form-select" formControlName="ciudad" aria-label="Default select example">
                            <option [value]="0" selected></option>
                            <option *ngFor="let ciudad of ciudades; let i = index" [value]="i + 1">{{ ciudad }}</option>
                        </select>
                        <!-- Ciudades seleccionadas -->
                        <div class="mt-3">
                            <ul class="lista d-flex flex-column">
                                <li class="p-2 btn btn-outline-orange mt-1"
                                    *ngFor="let ciudad of ciudades_seleccionadas; let i = index"
                                    (click)="onRemove('ciudades', i)">
                                    {{ ciudad }}
                                    <i class="bi bi-x-circle"></i>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Universidad -->
                    <div class="form-group col-12 col-lg-4 mt-3 mt-lg-0">
                        <h5 class="text-orange">Universidad</h5>
                        <select class="form-select" formControlName="universidad" aria-label="Default select example">
                            <option selected [value]="0"></option>
                            <option *ngFor="let university of universidades; let i = index" [value]="i+1" selected>{{
                                university }}</option>
                        </select>
                        <!-- Universidades seleccionadas -->
                        <div class="mt-3">
                            <ul class="lista d-flex flex-column">
                                <li class="p-2 btn btn-outline-orange mt-1"
                                    *ngFor="let universidades of universidades_seleccionadas; let i = index"
                                    (click)="onRemove('universidades', i)">
                                    {{ universidades }}
                                    <i class="bi bi-x-circle"></i>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class = "alert alert-info mt-2" *ngIf = "noResults">
                    <p class = "m-0">No se encontraron resultados.</p>
                </div>
                <div class="row mt-5 mt-lg-3 d-flex justify-content-end">
                    <div class="col-12 col-lg-4" style="text-align: right !important;">
                        <a class="btn-more m-0 pt-2 me-2 mb-2 mb-lg-0" (click)="onFilter()">Filtrar</a>
                        <a class="btn-more m-0 pt-2" (click)="cleanFilters()">Limpiar</a>
                    </div>
                </div>
            </form>
        </div>
    </section>
</div>
<div class="row mt-4 mb-2">
    <div class="col">
        <div style="overflow-x:auto;" class="border shadow">
            <table class="table table-striped table-personalized bg-white">
                <thead>
                    <tr>
                        <th scope="col" class = "text-orange">Dimensiones</th>
                        <th scope="col" class = "text-orange">Universidad</th>
                        <th scope="col" class = "text-orange">Facultad</th>
                        <th scope="col" class = "text-orange">Grupo de investigación</th>
                        <th scope="col" class = "text-orange">Linea de investigación</th>
                        <th scope="col" class = "text-orange">Departamento</th>
                        <th scope="col" class = "text-orange">Ciudad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let g of elements; let i = index;">
                        <td>{{ g.dimensiones }}</td>
                        <td>{{ g.universidad }}</td>
                        <td>{{ g.facultad }}</td>
                        <td>{{ g.grupos_investigacion }}</td>
                        <td>{{ g.linea_investigacion }}</td>
                        <td>{{ g.departamento }}</td>
                        <td>{{ g.ciudad }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>