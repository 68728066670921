<!-- ======= Footer ======= -->
<footer id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">

                <div class="col-lg-3 col-md-6 footer-info">
                    <img src="../../assets/img/octaedro/Logo.png" height="60px;" class="mb-3">
                    <h3>Octaedro</h3>
                    <p>
                        Bogotá, Colombia.<br>
                        <br>
                        <strong>Teléfono: </strong>+(57) 319-702-4360<br>
                        <strong>Correo electrónico: </strong>info@octaedro.com.co<br>
                    </p>
                    <div class="social-links mt-3">
                        <a href="https://twitter.com/_Octaedro" class="twitter" target="_blank"><i
                                class="bi bi-twitter"></i></a>
                        <a href="https://www.facebook.com/Octaedro-Diseñamos-Futuro-105460618361003" class="facebook"
                            target="_blank"><i class="bi bi-facebook"></i></a>
                        <a href="https://www.instagram.com/octaedro.com.co/" class="instagram" target="_blank"><i
                                class="bi bi-instagram"></i></a>
                        <a href="https://www.linkedin.com/in/octaedro/" class="linkedin" target="_blank"><i
                                class="bi bi-linkedin"></i></a>
                        <a href="https://wa.me/573197024360" class="whatsapp" target="_blank"><i
                                class="bi bi-whatsapp"></i></a>
                    </div>
                </div>
                <div class="col-4"></div>

                <div class="col-lg-2 col-md-6 footer-links">
                    <h4>Links útiles</h4>
                    <ul>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#home">Inicio</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a href="#test">Test
                                Vocacional</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#services">Servicios</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#equipo">Equipo</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="/terminos-y-condiciones" target = "_blank">Política de datos, términos y condiciones</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="/cookies" target = "_blank">Política de Cookies</a></li>
                    </ul>
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                    <h4>Nuestros servicios</h4>
                    <ul>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#services">Orientación profesional</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#services">Acompañamiento psicológico</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#services">Curaduría laboral y académica</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#services">Ética profesional y existencialismo</a></li>
                        <li><i class="bi bi-caret-right-fill me-1" style="font-size: 10px;"></i> <a
                                href="#services">Simulacro de entrevista</a></li>
                    </ul>
                </div>
                <!--
        <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
            <form action="" method="post">
            <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>

        </div>
        -->

            </div>
        </div>
    </div>

    <div class="container">
        <div class="copyright">
            &copy; Copyright 2021 <strong><span>Octaedro</span></strong>. Todos los derechos reservados.
        </div>
        <div class="credits">
            <!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: https://bootstrapmade.com/license/ -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/bootstrap-3-one-page-template-free-shuffle/ -->
            Desarrollado por <a target="_blank" href="https://alephmind.co" class="text-white alephmind">Aleph Mind</a>
        </div>
    </div>
</footer><!-- End Footer -->