<!-- ======= Contact Us Section ======= -->
<section id="contact" class="contact section-bg">

    <div class="container">
      <div class="section-title">
        <h2>Contáctanos</h2>
        <p>Si tienes comentarios, preguntas, o simplemente te gustaría contactarnos, siéntete libre de enviarnos un mensaje. Estamos aquí para guiarte en una de las decisiones más importantes de tu vida.</p>
      </div>
    </div>

    <div class="container-fluid">

      <div class="row">

        <div class="col-lg-6 d-flex align-items-stretch infos">

          <div class="row d-flex justify-content-center">

            <div class="col-lg-6 info d-flex flex-column align-items-stretch mb-4 m-lg-0">
              <i class="bi bi-map"></i>
              <h4>Ubicación</h4>
              <p>Bogotá, Colombia</p>
            </div>
            <div class="col-lg-6 info info-bg d-flex flex-column align-items-stretch mb-4 m-lg-0">
              <i class="bi bi-phone"></i>
              <h4>Llámanos</h4>
              <p>+(57) 319-702-4360</p>
            </div>
            <div class="col-lg-6 info info-bg d-flex flex-column align-items-stretch mb-4 m-lg-0">
              <i class="bi bi-envelope"></i>
              <h4>Escríbenos</h4>
              <!--
              <p>servicios@octaedro.com.co</p>
              -->
              <p>info@octaedro.com.co</p>
            </div>

            <div class="col-lg-6 info d-flex flex-column align-items-stretch mb-4 m-lg-0">
              <i class="bi bi-calendar-event"></i>
              <h4>Horario de Atención</h4>
              <p>Lunes - Viernes: 9AM a 5PM<br>Sábados: 9AM a 1PM</p>
            </div>
            
          </div>

        </div>

        <div class="col-lg-6 d-flex align-items-stretch contact-form-wrap">
          <form [formGroup] = "form" (submit) = "sendEmail()" class="php-email-form">
            <div class="row">

              <div class="col-md-6 form-group">
                <label for="name">Nombre</label>
                <input type="text" formControlName="name" class="form-control" id="name" placeholder="Nombre" required>
                <div *ngIf = "form?.get('name')?.touched && form?.get('name')?.invalid" class="alert alert-danger" role="alert">
                  Digite su nombre
                </div>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <label for="email">Correo electrónico</label>
                <input type="email" class="form-control" formControlName="email" id="email" placeholder="Correo electrónico" required>
                <div *ngIf = "form?.get('email')?.touched && form?.get('email')?.invalid" class="alert alert-danger" role="alert">
                  Correo electrónico inválido
                </div>
              </div>
              
            </div>
            <div class="form-group mt-3">
              <label for="subject">Asunto</label>
              <input type="text" class="form-control" formControlName="subject" id="subject" placeholder="Asunto" required>
              <div *ngIf = "form?.get('subject')?.touched && form?.get('subject')?.invalid" class="alert alert-danger" role="alert">
                Digite el asunto del mensaje
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="message">Mensaje</label>
              <textarea class="form-control" formControlName="message" rows="8" required></textarea>
              <div *ngIf = "form?.get('message')?.touched && form?.get('message')?.invalid" class="alert alert-danger" role="alert">
                Digite el mensaje
              </div>
            </div>
            <div class="recaptcha my-3">
              <re-captcha #captchaElem (resolved) = "resolved($event)" siteKey = "{{recaptchaCode}}"></re-captcha>
            </div>
            
            <!-- Submit buttons -->
            <div *ngIf = "!this.isLoading" class="text-center">
              <button type="submit" [disabled] = "form.invalid">Enviar mensaje</button>
            </div>
            <div *ngIf = "this.isLoading" class="text-center">
              <button type="submit" [disabled] = "form.invalid" style = "width: 166px;">
                <span class="spinner-border spinner-border-sm" role="status"
                      aria-hidden="true"></span>
                  Cargando...
              </button>
            </div>
            <!-- SUCESS AND ERROR -->
            <div *ngIf = "success" class="alert alert-success mt-3" role="alert">
              ¡Tu mensaje ha sido enviado! Estaremos contactándote pronto.
            </div>
            <div *ngIf = "error" class="alert alert-danger mt-3" role="alert">
              Error al enviar el correo. Intente más tarde.
            </div>

          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Us Section -->