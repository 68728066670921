<app-header></app-header>

<section class="container">
    <div class="px-4 py-4 shadow">

        <div class="row d-flex justify-content-center">
            <div class="animation-logo2 mt-5">
              <ng-lottie [options]="optionsLogo2" containerClass="animation-logo2"></ng-lottie>
            </div>
        </div>
     
        <h1 class="mb-5 text-orange text-center">Política de Cookies</h1>
        <p style="text-align: justify !important;">
            De conformidad con la legislación sobre protección de datos personales, Ley 1581 de 2012 y Decreto 1377 de
            2013,
            y demás normas que las modifiquen, adicionen o sustituyan, informamos OCTAEDRO Diseñamos Futuro S.A.S., en
            sus
            sitios web: octaedro.co, pueden utilizar cookies tanto propias como de terceros en diversas finalidades. Por
            lo
            anterior, a continuación, presentamos la POLÍTICA DE USO DE COOKIES adoptada por OCTAEDRO Diseñamos Futuro
            S.A.S., la cual contiene la información necesaria que debe conocer todo usuario de las páginas web sobre el
            uso
            de las cookies que realiza OCTAEDRO o los terceros que esta contrate. OCTAEDRO podrá modificar el presente
            documento en cualquier momento para mantenerlo vigente y actualizado. Por lo anterior, recomendamos a los
            usuarios revisar la fecha de elaboración o actualización de este, la cual se establece al final del presente
            documento.
            <br><br>
            En caso de dudas o inquietudes acerca del uso de cookies o sobre cualquiera de los puntos detallados en esta
            Política, por favor escriba al siguiente correo electrónico: servicios@octaedro.com.co
            Definición y tipos de cookies. Una cookie son pequeños archivos de información que se descargan en el
            ordenador,
            Smartphone o Tablet del usuario al acceder a determinadas páginas web para almacenar y recuperar información
            sobre la navegación que se efectúa desde dicho equipo. A través de las cookies, las páginas web recuerdan
            información sobre la visita de los usuarios, lo que permite que se proporcione una mejor y más segura
            experiencia de navegación en el mismo. Las cookies se asocian tanto a usuarios anónimos, es decir, aquellos
            que
            visitan los Portales sin identificarse o registrarse, como aquellos que si lo hacen.
            Por otro lado, las cookies no son un virus o cualquier otro tipo de programa malicioso que pueda dañar
            dispositivos de los usuarios. Las cookies no pueden borrar ni leer información del computador o dispositivo
            de
            los usuarios.
            <br><br>
            Las cookies se crean o actualizan en el computador o dispositivo del usuario de manera automática, cuando
            éste
            accede a la página web de OCTAEDRO.COM.CO, lo cual permite a la misma o a las terceras personas que esta
            contrate, realizar seguimiento a las cookies del usuario y por ende a la información que estas cookies
            contienen
            u obtienen del usuario. Es importante aclarar que las cookies solo son leídas por el sitio web que las creó.
            OCTAEDRO Diseñamos Futuro S.A.S. podrá compartir información obtenida a través de las cookies con personas
            externas o terceros (aliados, clientes, proveedores o empresas vinculadas a OCTAEDRO), con el fin de mejorar
            los
            servicios al usuario. Así mismo, la información que se recibe a través de las cookies será utilizada por
            OCTAEDRO y los anteriores terceros, para los fines descritos en el presente documento, y cualquiera de sus
            actualizaciones.
            <br><br>
            Existen diferentes tipos de cookies, las cuales pueden ser clasificadas en función a quien las ha creado,
            cuanto
            tiempo duran y, finalmente, que finalidad tienen.
            <br><br>
            <strong class = "text-orange">Creación</strong> - Cookies propias o de terceros: son propias cuando las cookies se gestionan desde el terminal o
            dominio de un mismo editor, mientras que son de terceros, cuando no las envía el propio editor, sino otra
            entidad.
            <br><br>
            <strong class = "text-orange">Tiempo</strong> - Cookies de sesión y persistentes: en cuanto a las cookies de sesión, los datos recolectados sólo se
            almacenarán mientras el usuario este navegando por la página web. En el caso de las persistentes, los datos
            continúan almacenados en el terminal y se puede acceder a ellos durante un período de tiempo determinado.
            <br><br>
            <strong class = "text-orange">Finalidad</strong> - Cookies técnicas/personalización/análisis/publicitarias: las cookies técnicas son aquellas que
            permiten controlar el tráfico y la comunicación de datos; las cookies de personalización, las que dejan a
            los
            usuarios acceder según algunas características propias que se recogen (navegador, idioma, etc.), como, por
            ejemplo, personalizar la página de inicio del buscador. Las cookies de análisis recogen datos sobre el
            comportamiento de los usuarios y permiten elaborar un perfil de usuario. De este modo se pueden analizar y
            detectar hábitos de navegación y mejorar la web, blog o Prueba de Orientación Vocacional para adaptarse a
            las
            necesidades de navegación de los usuarios. Por último, las publicitarias recogen datos sobre la gestión de
            los
            espacios publicitarios. Este tipo de cookies permiten, por ejemplo, mostrarles a los usuarios banners de
            publicidad de la que puedan estar potencialmente interesados.
            <br><br>
            En particular, OCTAEDRO utiliza las siguientes cookies con los siguientes propósitos o finalidades:
            <br><br>
            <strong class = "text-orange">1.</strong> Permitir una mejor experiencia para los usuarios dentro de la página.
            <br><br>
            <strong class = "text-orange">2.</strong> Conocer los patrones de uso de nuestros servicios y navegación de la página que permitan realizar ajustes
            <br><br>
            dentro de la navegación y forma de interactuar con nuestros sitios web.
            <br><br>
            <strong class = "text-orange">3.</strong> Personalizar la experiencia mostrando información acorde a las necesidades del cliente.
            <br><br>
            <strong class = "text-orange">4.</strong> Recoger datos sobre los intereses y preferencias de los usuarios para realizar ajustes en nuestras
            metodologías.
            <br><br>
            <strong class = "text-orange"><h5 class = "mb-3">Desactivación de cookies.</h5></strong>
            
            Puede desactivar las cookies utilizadas en este sitio web sin que afecte al funcionamiento de este; sin
            embargo,
            la información captada por estas cookies sobre el uso de nuestra web y sobre el éxito de los anuncios
            mostrados
            en ella permite mejorar nuestros servicios y obtener ingresos que nos permiten ofrecerle de forma gratuita
            muchos contenidos.
            <br><br>
            El usuario puede deshabilitar tanto la instalación de las cookies como el uso de estas. Para deshabilitar la
            instalación y uso de cookies el usuario deberá realizar el procedimiento por navegador tal como se indica a
            continuación:
            <br><br>
            Internet Explorer: Herramientas -> Opciones de Internet -> Privacidad -> Configuración.
            <br><br>
            Para más información, puede consultar el Soporte de Microsoft o la Ayuda del navegador.
            <br><br>
            Firefox: Herramientas -> Opciones -> Privacidad -> Historial -> Configuración Personalizada. Para más
            información, puede consultar el Soporte de Mozilla o la Ayuda del navegador.
            <br><br>
            Chrome: Configuración -> Mostrar opciones avanzadas -> Privacidad -> Configuración de contenido. Para más
            información, puede consultar el Soporte de Google o la Ayuda del navegador.
            <br><br>
            Safari: Preferencias -> Seguridad. Para más información, puede consultar el Soporte de Apple o la Ayuda del
            navegador.
            <br><br>
            <strong class = "text-orange">¿QUÉ OCURRE SI SE DESHABILITAN LAS COOKIES?</strong>
            Si el usuario deshabilita la instalación o el uso de las cookies para los Portales, podrá perder o afectar
            algunas funcionalidades del sitio, como, por ejemplo:
            <br><br>
            <strong class = "text-orange">1.</strong> Poder ingresar a los Portales sin necesidad de indicar en cada ocasión su usuario y contraseña, esto le
            ahorrará tiempo y le permitirá utilizar funcionalidades como boletines, alertas, noticias guardadas, entre
            otros.
            <br><br>
            <strong class = "text-orange">2.</strong> Publicación de comentarios dentro de los Portales.
            <br><br>
            <strong class = "text-orange">3.</strong> Acceso al contenido sin restricción dentro de los Portales.
            <br><br>
            <strong class = "text-orange">4.</strong> Seguridad en el uso de la información que se ingresa en los Portales.
            <br><br>
            <strong class = "text-orange">5.</strong> Rapidez en el uso de algún servicio dentro de los Portales.

        </p>
    </div>
</section>


<app-footer></app-footer>