<div class="d-sm-flex align-items-center justify-content-between mt-5">
  <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53">Buscador</h1>
</div>
<!--
<p class="my-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis deserunt corrupti optio placeat
    recusandae sint totam dolor, modi, distinctio eos ipsum consectetur quis tempore nobis earum amet rerum. Illum
    laboriosam eveniet eum eligendi odio asperiores repellendus aspernatur voluptate ab. Rerum dolor hic sed ex
    debitis praesentium nostrum ipsam a unde!</p>
-->

<div class="px-4 pt-2 pb-4 mt-4 bg-white border rounded shadow">
  <!-- Formularios -->
  <section style="padding: 0px" class="mt-3">
    <div class="row">
      <p class="alert alert-danger mb-4" *ngIf="error">
        Ha ocurrido un error. Intente más tarde.
      </p>
      <form class="form" [formGroup]="form">
        <div class="row">
          <!-- Dimensión -->
          <div class="form-group col-12 col-lg-4">
            <h5 class="text-orange">Dimensión</h5>
            <select
              class="form-select"
              formControlName="dimension"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option
                *ngFor="let dim of dimensiones; let i = index"
                [value]="i + 1"
              >
                {{ dim }}
              </option>
            </select>
            <!-- Dimensiones seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let dim of dimensiones_seleccionadas; let i = index"
                  (click)="onRemove('dimensiones', i)"
                >
                  {{ dim }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>

          <!-- nucleos de conocimiento-->
          <!-- {{ areas }} -->
          <div class="form-group col-12 col-lg-4 mt-3 mt-lg-0">
            <h5 class="text-orange">Nucleos de conocimiento</h5>
            <select
              class="form-select"
              formControlName="nucleo"
              aria-label="Default select example"
            >
              <option [value]="0" selected disabled></option>
              <option *ngFor="let nucleo of nucleos; let i = index" [value]="i + 1">
                {{ nucleo }}
              </option>
            </select>
            <!-- Areas seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let nucleo of nucleos_seleccionadas; let i = index"
                  (click)="onRemove('nucleos', i)"
                >
                  {{ nucleo }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>

          <!-- Sectores -->
          <div class="form-group col-12 col-lg-4 mt-3 mt-lg-0">
            <h5 class="text-orange">Sector economico</h5>
            <select
              class="form-select"
              formControlName="sector"
              aria-label="Default select example"
            >
              <option selected [value]="0"></option>
              <option
                *ngFor="let sector of sectores; let i = index"
                [value]="i + 1"
                selected
              >
                {{ sector }}
              </option>
            </select>
            <!-- Sectores seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let sector of sectores_seleccionadas; let i = index"
                  (click)="onRemove('sectores', i)"
                >
                  {{ sector }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>

          <!-- Area -->
          <div class="form-group col-12 col-lg-4 mt-3 mt-lg-0">
            <h5 class="text-orange">Areas de conocimiento</h5>
            <select
              class="form-select"
              formControlName="area"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option *ngFor="let area of areas; let i = index" [value]="i + 1">
                {{ area }}
              </option>
            </select>
            <!-- Areas seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let area of areas_seleccionadas; let i = index"
                  (click)="onRemove('areas', i)"
                >
                  {{ area }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="alert alert-info mt-2" *ngIf="noResults">
          <p class="m-0">No se encontraron resultados.</p>
        </div>
        <div class="row mt-5 mt-lg-3 d-flex justify-content-end">
          <div class="col-12 col-lg-4" style="text-align: right !important">
            <a class="btn-more m-0 pt-2 me-2 mb-2 mb-lg-0" (click)="onFilter()"
              >Filtrar</a
            >
            <a class="btn-more m-0 pt-2" (click)="cleanFilters()">Limpiar</a>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
<div class="row mt-4 mb-2">
  <div class="col">
    <div style="overflow-x: auto" class="border shadow">
      <table class="table table-striped table-personalized bg-white">
        <thead>
          <tr>
            <th scope="col" class="text-orange">Dimensiones</th>
            <th scope="col" class="text-orange">Núcleos de conocimiento</th>
            <th scope="col" class="text-orange">Sectores económico</th>
            <th scope="col" class="text-orange">Areas de conocimiento</th>
            <th scope="col" class="text-orange">Graduados</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let g of elements">
            <td>{{ g.dimensiones }}</td>
            <td>{{ g.nucleos_de_conocimiento }}</td>
            <td>{{ g.sectores_economicos }}</td>
            <td>{{ g.areas }}</td>
            <td>
              {{ this.formatNumber(this.parseInt(g.graduados), "en-US") }}
            </td>
         
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- Page Heading -->
<!--
<div class="d-sm-flex align-items-center justify-content-between mb-4 mt-5">
    <h1 class="h3 mb-0 text-gray-800">Buscador</h1>
</div>
<div class="row">
    <div class="col-md-12 mx-auto">
        <div class="md-form">
            <input type="text" [(ngModel)]="searchText" class="form-control" id="search" mdbInput />
        </div>
    </div>
</div>
<div style="overflow-x:auto; overflow-y: auto; max-height: 700px;">
    <table mdbTable class="z-depth-1 table table-striped">
        <thead>
            <tr>
                <th scope="col">Areas</th>
                <th scope="col">Núcleos de conocimiento</th>
                <th scope="col">Sectores económicos</th>
                <th scope="col">Graduados</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let g of elements">
                <td>{{ g.areas }}</td>
                <td>{{ g.nucleos_de_conocimiento }}</td>
                <td>{{ g.sectores_economicos }}</td>
                <td>{{ g.graduados }}</td>
            </tr>
        </tbody>
    </table>
</div>
-->
