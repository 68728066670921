
<div class="d-sm-flex align-items-center justify-content-between mt-5">
  <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53">Buscador</h1>
</div>
<div class="px-4 pt-2 pb-4 mt-4 bg-white border rounded shadow">
  <!-- Formularios -->
  <section style="padding: 0px" class="mt-3">
    <div class="row">
      <p class="alert alert-danger mb-4" *ngIf="error">
        Ha ocurrido un error. Intente más tarde.
      </p>
      <form class="form" [formGroup]="form">
        <div class="row">
          <!-- Dimensión -->
          <div class="form-group col-12 col-lg-4">
            <h5 class="text-orange">Dimensión</h5>
            <select
              class="form-select"
              formControlName="dimension"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option
                *ngFor="let dim of dimensiones; let i = index"
                [value]="i + 1"
              >
                {{ dim }}
              </option>
            </select>
            <!-- Dimensiones seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let dim of dimensiones_seleccionadas; let i = index"
                  (click)="onRemove('dimensiones', i)"
                >
                  {{ dim }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>

          <!-- nucleos de conocimiento-->
          <!-- {{ areas }} -->
          <div class="form-group col-12 col-lg-4 mt-3 mt-lg-0">
            <h5 class="text-orange">Objetivos de desarrollo sostenible</h5>
            <select
              class="form-select"
              formControlName="objetivo"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option *ngFor="let ob of objetivos_de_desarrollo; let i = index" [value]="i + 1">
                {{ ob }}
              </option>
            </select>
            <!-- Areas seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let ob of objetivos_de_desarrollo_seleccionadas; let i = index"
                  (click)="onRemove('objetivo', i)"
                >
                  {{ ob }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="alert alert-info mt-2" *ngIf="noResults">
          <p class="m-0">No se encontraron resultados.</p>
        </div>
        <div class="row mt-5 mt-lg-3 d-flex justify-content-end">
          <div class="col-12 col-lg-4" style="text-align: right !important">
            <a class="btn-more m-0 pt-2 me-2 mb-2 mb-lg-0" (click)="onFilter()"
              >Filtrar</a
            >
            <a class="btn-more m-0 pt-2" (click)="cleanFilters()">Limpiar</a>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
<div class="row mt-4 mb-2">
  <div class="col">
    <div style="overflow-x: auto" class="border shadow">
      <table class="table table-striped table-personalized bg-white">
        <thead>
          <tr>
            <th scope="col" class="text-orange">Dimensiones</th>
            <th scope="col" class="text-orange"></th>
            <th scope="col" class="text-orange">
              Objetivo de desarrollo sostenible
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let g of elements">
            <td>
              <img
                [src]="mapaObjetivos[g.objetivo]"
                width="200px;"
                height="200px;"
              />
            </td>
            <td>{{ g.dimensiones }}</td>
            <td>{{ g.descripcion}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>