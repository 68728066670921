<section style="padding: 0px">
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Sidebar -->
    <ul
      class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      <!-- Sidebar - Brand -->
      <a
        class="sidebar-brand d-flex align-items-center justify-content-center"
        href="index.html"
      >
        <div class="sidebar-brand-icon rotate-n-15">
          <i class="fas fa-laugh-wink"></i>
        </div>

        <div class="sidebar-brand-text d-flex flex-column align-items-center">
          <img
            class="mb-3"
            width="50px"
            src="../../assets/img/octaedro/Logo.png"
          />
          <p class="mb-0">Octaedro</p>
        </div>
      </a>

      <!-- Divider -->
      <hr class="sidebar-divider my-0" />

      <!-- Nav Item - Dashboard -->
      <li class="nav-item active text-center">
        <a class="nav-link" (click)="changeTab(1)">
          <i class="bi bi-toggles me-2" style="color: #e8c95c"></i>
          <span style="color: #e8c95c">Panel de Control</span></a
        >
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider" />

      <!-- Heading -->
      <div class="sidebar-heading">Mis resultados</div>

      <!-- Nav Item - Resultados del test -->
      <li class="nav-item mt-1">
        <a class="nav-link active" (click)="changeTab(1)">
          <i class="bi bi-bar-chart mx-2" style="color: #e8c95c"></i>
          <span style="color: #e8c95c">Resultados del test</span></a
        >
      </li>

      <!-- Nav Item - Programas académicos -->
      <li class="nav-item mt-1">
        <a class="nav-link active" (click)="changeTab(2)">
          <i class="bi bi-eyeglasses mx-2"></i>
          <span>Programas académicos</span></a
        >
      </li>
      <!-- Nav Item - Grupos de Investigación -->
      <li class="nav-item mt-1">
        <a class="nav-link" (click)="changeTab(3)">
          <i class="bi bi-eyedropper mx-2"></i>
          <span>Grupos de Investigación</span></a
        >
      </li>

      <!-- Nav Item - Objetivos de desarrollo sostenible -->
      <li class="nav-item mt-1">
        <a class="nav-link" (click)="changeTab(4)">
          <i class="bi bi-diagram-3 mx-2"></i>
          <span>Objetivos de desarrollo</span></a
        >
      </li>

      <!-- Nav Item - Habilidades por Sectores -->
      <li class="nav-item mt-1">
        <a class="nav-link" (click)="changeTab(5)">
          <i class="bi bi-bag mx-2"></i>
          <span>Ocupaciones</span></a
        >
      </li>

      <!-- Nav Item - Salarios -->
      <li class="nav-item mt-1">
        <a class="nav-link" (click)="changeTab(6)">
          <i class="bi bi-arrow-up-right mx-2"></i>
          <span>Salarios</span></a
        >
      </li>

      <!-- Nav Item - Sectores económicos y graduados -->
      <li class="nav-item mt-1">
        <a class="nav-link" (click)="changeTab(7)">
          <i class="bi bi-building mx-2"></i>
          <span>Graduados</span></a
        >
      </li>

      <!-- Nav Item - Utilities Collapse Menu -->
      <!--
            <li class="nav-item">
                <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i class="fas fa-fw fa-wrench"></i>
                    <span>Busca</span>
                </a>
                <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div class="bg-white py-2 collapse-inner rounded">
                        <h6 class="collapse-header">Custom Utilities:</h6>
                        <a class="collapse-item" href="utilities-color.html">Colors</a>
                        <a class="collapse-item" href="utilities-border.html">Borders</a>
                        <a class="collapse-item" href="utilities-animation.html">Animations</a>
                        <a class="collapse-item" href="utilities-other.html">Other</a>
                    </div>
                </div>
            </li>
            -->

      <!-- Divider -->
      <hr class="sidebar-divider" />

      <li
        class="nav-item mt-1 ms-4"
        style="font-size: 12px; color: #e8c95c !important"
      >
        Versión {{ version }}
      </li>

      <!-- Sidebar Message -->
      <!--
            <div class="sidebar-card d-none d-lg-flex">
                <img class="sidebar-card-illustration mb-2" src="img/undraw_rocket.svg" alt="...">
                <p class="text-center mb-2"><strong>SB Admin Pro</strong> is packed with premium features, components, and more!</p>
                <a class="btn btn-success btn-sm" href="https://startbootstrap.com/theme/sb-admin-pro">Upgrade to Pro!</a>
            </div>
            -->
    </ul>
    <!-- End of Sidebar -->

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav
          class="
            navbar navbar-expand navbar-light
            bg-white
            topbar
            mb-4
            static-top
            shadow
          "
        >
          <!-- Sidebar Toggle (Topbar) -->
          <button
            id="sidebarToggleTop"
            class="btn btn-link d-md-none rounded-circle mr-3"
          >
            <i class="fa fa-bars"></i>
          </button>

          <!-- Topbar Search -->
          <!--
                    <form
                        class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                        <div class="input-group">
                            <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button">
                                    <i class="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                    -->

          <!-- Topbar Navbar -->
          <ul class="navbar-nav ml-auto">
            <!-- Nav Item - Search Dropdown (Visible Only XS) -->
            <li class="nav-item dropdown no-arrow d-sm-none">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="searchDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fas fa-search fa-fw"></i>
              </a>
              <!-- Dropdown - Messages -->
              <div
                class="
                  dropdown-menu dropdown-menu-right
                  p-3
                  shadow
                  animated--grow-in
                "
                aria-labelledby="searchDropdown"
              >
                <form class="form-inline mr-auto w-100 navbar-search">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control bg-light border-0 small"
                      placeholder="Search for..."
                      aria-label="Search"
                      aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="button">
                        <i class="fas fa-search fa-sm"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>

            <!-- Nav Item - User Information -->
            <li class="nav-item dropdown no-arrow">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span
                  class="mr-2 d-none d-lg-inline text-gray-600 me-2"
                  style="font-size: 15px"
                  >{{ this.userName }}</span
                >
                <img
                  class="img-profile rounded-circle me-2"
                  [src]="
                    imageUrl || '../../assets/img/octaedro/icono-usuario.png'
                  "
                />
              </a>
              <!-- Dropdown - User Information -->
              <div
                class="
                  dropdown-menu dropdown-menu-right
                  shadow
                  animated--grow-in
                "
                aria-labelledby="userDropdown"
              >
                <a
                  class="dropdown-item"
                  href="/add-user"
                  *ngIf="this.isAdmin == 'true'"
                >
                  <i class="bi bi-key-fill me-2" style="color: #d06a53"></i>
                  Agregar usuario
                </a>
                <a class="dropdown-item" href="/profile">
                  <i class="bi bi-file-person me-2" style="color: #d06a53"></i>
                  Perfil
                </a>
                <a class="dropdown-item" href="/change_password">
                  <i class="bi bi-gear-fill me-2" style="color: #d06a53"></i>
                  Cambiar contraseña
                </a>
                <!--
                                <a class="dropdown-item" href="#">
                                    <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Settings
                                </a>
                                <a class="dropdown-item" href="#">
                                    <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Activity Log
                                </a>
                                -->
                <div class="dropdown-divider"></div>
                <!--
                                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                                    <i class="bi bi-door-open me-2" style = "color: #d06a53;"></i>
                                    Salir
                                </a>
                                -->
                <a class="dropdown-item" href="#" (click)="onLogout()">
                  <i class="bi bi-door-open me-2" style="color: #d06a53"></i>
                  Salir
                </a>
              </div>
            </li>
          </ul>
        </nav>
        <!-- End of Topbar -->

        <!-- Completar encuesta -->
        <div
          class="container-fluid px-4 pt-3 m-0"
          *ngIf="surveyCompleted == '0' && tab >= 1"
        >
          <div class="alert alert-warning">
            ¡Hola! Hemos detectado que aún no has completado nuestra encuesta
            sociodemográfica. Complétala para que podamos seguir mejorando las
            recomendaciones que te hace nuestra plataforma.
            <br />
            <a class="mt-2 btn btn-outline-primary" href="/survey"
              >Llenar encuesta</a
            >
          </div>
        </div>

        <!-- Hace el test de nuevo -->
        <div
          class="container-fluid px-4 pt-3 m-0"
          *ngIf="testAvailable == '1' && tab >= 1"
        >
          <div class="alert alert-info">
            ¡Hola! Se ha habilitado otro intento para que realices el test
            nuevamente. Puedes acceder al test oprimiendo el siguiente botón:
            <br />
            <a class="mt-2 btn btn-outline-primary" href="/test">Ir al test</a>
          </div>
        </div>

        <div class="container-fluid px4 py-3 m-0 text-center" *ngIf="tab == 0">
          <img src="../../assets/gif/math_carrera.gif" />
        </div>

        <!-- Tu perfil profesional -->
        <!--
                <div class="container-fluid px-4 py-3 pb-0">
                    <div class="d-sm-flex align-items-center justify-content-between my-2">
                        <h2 style="color: #d06a53 !important;">Tu perfil profesional</h2>
                    </div>
                    <p class="mt-3">De acuerdo a los resultados de tu test, este es el orden de tus dimensiones:</p>
                    <div class="row border shadow m-0 mb-4" *ngIf="mapaColores">
                        <div class="col-12 col-lg-2  d-flex flex-column m-0 p-0">
                            <div class="bg-white text-center p-2 fw-bold">Primer lugar</div>
                            <div class="text-center p-2"
                                [ngStyle]="{'background-color': mapaColores[labelsResults[0]]}">
                                {{labelsResults[0]}}</div>
                        </div>
                        <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
                            <div class="bg-white text-center p-2 fw-bold">Segundo lugar</div>
                            <div class="text-center p-2"
                                [ngStyle]="{'background-color': mapaColores[labelsResults[1]]}">
                                {{labelsResults[1]}}</div>
                        </div>
                        <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
                            <div class="bg-white text-center p-2 fw-bold">Tercer lugar</div>
                            <div class="text-center p-2"
                                [ngStyle]="{'background-color': mapaColores[labelsResults[2]]}">
                                {{labelsResults[2]}}</div>
                        </div>
                        <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
                            <div class="bg-white text-center p-2 fw-bold">Cuarto lugar</div>
                            <div class="text-center p-2"
                                [ngStyle]="{'background-color': mapaColores[labelsResults[3]]}">
                                {{labelsResults[3]}}</div>
                        </div>
                        <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
                            <div class="bg-white text-center p-2 fw-bold">Quinto lugar</div>
                            <div class="text-center p-2"
                                [ngStyle]="{'background-color': mapaColores[labelsResults[4]]}">
                                {{labelsResults[4]}}</div>
                        </div>
                        <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
                            <div class="bg-white text-center p-2 fw-bold">Sexto lugar</div>
                            <div class="text-center p-2"
                                [ngStyle]="{'background-color': mapaColores[labelsResults[5]]}">
                                {{labelsResults[5]}}</div>
                        </div>
                    </div>
                </div>                                                                                                                      
                -->

        <!-- Tu perfil según Holland -->
        <div class="container-fluid px-4 py-3 pt-2" *ngIf="tab >= 1">
          <p class="mt-3">
            Con base en tus dimensiones, este es el
            <strong class="text-orange">perfil de Holland</strong> que más se
            parece al tuyo. A continuación, encontrarás las recomendaciones que
            nuestros algoritmos tienen para ti.
          </p>
          <div class="row border shadow m-0 mb-4" *ngIf="mapaColores">
            <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
              <div class="bg-white text-center p-2 fw-bold">Primer lugar</div>
              <div
                class="text-center p-2"
                [ngStyle]="{
                  'background-color': mapaColores[podiumHolland[0]]
                }"
              >
                {{ podiumHolland[0] }}
              </div>
            </div>
            <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
              <div class="bg-white text-center p-2 fw-bold">Segundo lugar</div>
              <div
                class="text-center p-2"
                [ngStyle]="{
                  'background-color': mapaColores[podiumHolland[1]]
                }"
              >
                {{ podiumHolland[1] }}
              </div>
            </div>
            <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
              <div class="bg-white text-center p-2 fw-bold">Tercer lugar</div>
              <div
                class="text-center p-2"
                [ngStyle]="{
                  'background-color': mapaColores[podiumHolland[2]]
                }"
              >
                {{ podiumHolland[2] }}
              </div>
            </div>
            <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
              <div class="bg-white text-center p-2 fw-bold">Cuarto lugar</div>
              <div
                class="text-center p-2"
                [ngStyle]="{
                  'background-color': mapaColores[podiumHolland[3]]
                }"
              >
                {{ podiumHolland[3] }}
              </div>
            </div>
            <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
              <div class="bg-white text-center p-2 fw-bold">Quinto lugar</div>
              <div
                class="text-center p-2"
                [ngStyle]="{
                  'background-color': mapaColores[podiumHolland[4]]
                }"
              >
                {{ podiumHolland[4] }}
              </div>
            </div>
            <div class="col-12 col-lg-2 d-flex flex-column m-0 p-0">
              <div class="bg-white text-center p-2 fw-bold">Sexto lugar</div>
              <div
                class="text-center p-2"
                [ngStyle]="{
                  'background-color': mapaColores[podiumHolland[5]]
                }"
              >
                {{ podiumHolland[5] }}
              </div>
            </div>
          </div>
        </div>

        <!-- Show Test Results -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 1">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Resultados del Test
            </h1>
          </div>

          <!-- Felicitaciones -->
          <div
            class="row mb-4 d-flex align-items-center justify-content-center"
          >
            <div class="col-12">
              <p>
                <span class="text-orange fw-bold">¡Felicitaciones</span>, has
                completado la prueba con éxito! A continuación, encontrarás tu
                perfil profesional y resultados del test, de acuerdo a las
                dimensiones realista, investigador, artístico, social,
                emprendedor y convencional, ¡comencemos!
              </p>
              <p>
                Ahora mira tus porcentajes y características de acuerdo a cada
                dimensión <strong class="text-orange">dando click</strong> a las
                imágenes:
              </p>
            </div>
            <div class="col-6 mb-5 animation-ser mt-2">
              <ng-lottie
                [options]="optionsSer"
                containerClass="animation-ser"
              ></ng-lottie>
            </div>
          </div>

          <p class="mt-lg-5">
            Ahora mira tus porcentajes y características de acuerdo a cada
            dimensión:
          </p>
          <!-- Tabla con resultados -->
          <!--
                    <div class="row mb-4">
                        <div class="col">
                            <div style="overflow-x:auto;" class="border shadow">
                                <table class="table table-light results-table mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Resultados porcentuales totales</th>
                                            <th scope="col">Dimensión</th>
                                            <th scope="col">Características Personales</th>
                                            <th scope="col">Capacidades motivacionales</th>
                                            <th scope="col">Intereses y valores</th>
                                            <th scope="col">Cargos posibles</th>
                                            <th scope="col">Lo que evita</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="finalResults">
                                        <tr class="col">
                                            <th scope="row">
                                                <div class="d-flex align-items-center justify-content-center text-center rounded-circle mt-2 ms-2"
                                                    style="width: 150px; height: 150px; border: 5px solid #dc3545;"> 
                                                    <p class="mb-0">{{finalResults[0]}}%</p>
                                                </div>
                                            </th>
                                            <td class="table-danger">
                                                <div>
                                                    <p class="mb-0 fw-bold">Realista</p>
                                                </div>
                                            </td>
                                            <td>Este ambiente incluye personas que se destacan por sus capacidades
                                                mecánicas y deportivas. Prefieren trabajar con maquinaria, equipamiento,
                                                plantas y animales.
                                                Es posible que también les guste trabajar fuera de una oficina.</td>
                                            <td>Realizar cosas de manera concreta, hacer objetos o a ejercer una
                                                actividad que implica el uso de sus manos o de herramientas y técnicas
                                                manuales. Voluntad de no contentarse de una función intelectual y
                                                puramente conceptual.</td>
                                            <td>Involucra la necesidad implicación física en su trabajo, lejos de
                                                trabajo, lejos de la oficina, y/o la necesidad de ejercer una actividad
                                                al aire libre o permitiendo permitiendo lazo estrecho con la naturaleza.
                                            </td>
                                            <td>La agricultura, el medio ambiente, empleo en la industria sobre una
                                                cadena de producción, o para un puesto de instalador, reparador o
                                                administrador como informático, redes, etc.</td>
                                            <td>La pasividad y dejar que otros resuelvan asuntos que él mismo puede
                                                desarrollar. </td>

                                        </tr>
                                        <tr class="col">
                                            <th scope="row">
                                                <div class="d-flex align-items-center justify-content-center text-center rounded-circle mt-2 ms-2"
                                                    style="width: 150px; height: 150px; border: 5px solid #ffc107;">
                                                    <p class="mb-0">{{finalResults[1]}}%</p>
                                                </div>
                                            </th>
                                            <td class="table-warning">
                                                <div>
                                                    <p class="mb-0 fw-bold">Investigador</p>
                                                </div>
                                            </td>
                                            <td>Este ambiente representa a los que prefieren profesiones científicas e
                                                intelectuales. Disfrutan de reunir información, identificar teorías o
                                                hechos y analizar e interpretar información.</td>
                                            <td>Desarrollar y valorar competencias, aconsejar a individuo o a empresas
                                                sobre estas áreas, investigar, transmitir conocimiento.</td>
                                            <td>La tecnicidad, la precisión, la profesionalidad, el rigor y el sentido
                                                del detalle, la enseñanza, las ciencias, los métodos novedosos.
                                            </td>
                                            <td>Desarrollo informático, venta de soluciones técnicas, análisis
                                                financiero, contabilidad, búsqueda científica, formación, consejo.</td>
                                            <td>La falta de profesionalismo, la imprecisión, la incompetencia,la
                                                cotidianidad,la subjetividad. </td>
                                        </tr>

                                        <tr class="col">
                                            <th scope="row">
                                                <div class="d-flex align-items-center justify-content-center text-center rounded-circle mt-2 ms-2"
                                                    style="width: 150px; height: 150px; border: 5px solid #20c997;">
                                                    <p class="mb-0">{{finalResults[2]}}%</p>
                                                </div>
                                            </th>
                                            <td class="table-success">
                                                <div>
                                                    <p class="mb-0 fw-bold">Social</p>
                                                </div>
                                            </td>
                                            <td>Este ambiente está representado por personas sociales que disfrutan al
                                                ayudar a otros. Prefieren trabajar en grupos y se caracterizan también
                                                por sus grandes habilidades de comunicación.</td>
                                            <td>Explicar, seducir,negociar, presentar, aconsejar y vender, tener
                                                contactos, comunicar con los clientes o internamente. Ayudar a los
                                                otros, prestar servicio, escuchar, informar, formar, ayudar a cada uno a
                                                progresar, aconsejar y orientar.</td>
                                            <td>Las buenas relaciones, los viajes y las otras culturas, las
                                                negociaciones, el ambiente de trabajo.
                                                La empatía, las relaciones humanas, el bienestar personal, la
                                                psicología, la medicina, el humanismo, la justicia.
                                            </td>
                                            <td>Encargado/a de clientela, soporte de ventas, comunicación y relaciones
                                                públicas. Profesor/a, psicólogo/a, responsable
                                                de formación, servicio post-venta,
                                                recursos humanos. </td>
                                            <td>La ausencia de contactos con el entorno exterior, el trabajo solitario.
                                                El mercantilismo, el individualismo, el hecho de no hacer nada por
                                                mejorar la vida de los otros. </td>
                                        </tr>
                                        <tr class="col">
                                            <th scope="row">
                                                <div class="d-flex align-items-center justify-content-center text-center rounded-circle mt-2 ms-2"
                                                    style="width: 150px; height: 150px; border: 5px solid #0dcaf0;">
                                                    <p class="mb-0">{{finalResults[3]}}%</p>
                                                </div>
                                            </th>
                                            <td class="table-info">
                                                <div>
                                                    <p class="mb-0 fw-bold">Emprendedor</p>
                                                </div>
                                            </td>
                                            <td>Este ambiente incluye a las personas con personalidad administrativa.
                                                Pueden
                                                conectar eficientemente sus ideas y opiniones con los demás y
                                                persuadirlos.
                                                Además, confían mucho en sí mismos y tienen la energía necesaria para
                                                lograr sus aspiraciones.</td>
                                            <td>Animar una reunión, supervisar, motivar a los colaboradores, manejar
                                                conflictos, reconciliar, convencer, planear las acciones, decidir y
                                                cortar. Improvisar, gestionar las urgencias, ir sobre el terreno,
                                                desarrollar nuevas actividades, materializar proyectos o ideas,
                                                conseguir ventas,lanzar desafíos. </td>
                                            <td>El management, las orientaciones estratégicas, la dirección de
                                                proyectos. La acción a corto plazo, la aventura, la toma de riesgos, los
                                                negocios y el beneficio, las nuevas modas.
                                            </td>
                                            <td> Manager, jefe de ventas, recursos humanos, dirección financiera,
                                                relaciones públicas. Comercial, marketing operacional, compras,
                                                distribución, logística, desarrollo de proyectos o negocios, dirección
                                                de PYME.</td>
                                            <td> La ausencia de responsabilidades, la imposibilidad de tomar decisiones,
                                                supervisores demasiado controladores. La inmovilidad y la inacción,
                                                trabajar encerrado en un despacho, la falta de iniciativa.</td>
                                        </tr>
                                        <tr class="col">
                                            <th scope="row">
                                                <div class="d-flex align-items-center justify-content-center text-center rounded-circle mt-2 ms-2"
                                                    style="width: 150px; height: 150px; border: 5px solid #0d6efd;">
                                                    <p class="mb-0">{{finalResults[4]}}%</p>
                                                </div>
                                            </th>
                                            <td class="table-primary">
                                                <div>
                                                    <p class="mb-0 fw-bold">Artístico</p>
                                                </div>
                                            </td>
                                            <td>Este ambiente incluye a las personas que aprecian las cualidades
                                                estéticas que expresan a través de su trabajo artístico y literario. Se
                                                caracterizan por su flexibilidad y no conformidad o compromiso con un
                                                sistema específico.</td>
                                            <td>Concebir, redactar, razonar por analogía, dibujar, imaginar, percibir,
                                                innovar, debatir, cuestionar el conformismo.</td>
                                            <td>Los símbolos, el futuro, la originalidad, los conceptos y su
                                                concreción, la estética, las emociones y su representación.
                                            </td>
                                            <td>Artista, grafista, arquitecto, jefe de proyecto multimedia, marketing y
                                                publicidad.</td>
                                            <td>La rutina, el conformismo,el no debatir ideas, los prejuicios, las
                                                actividades de cálculo,la técnica y las ciencias, las actividades
                                                físicas.</td>

                                        </tr>
                                        <tr class="col">
                                            <th scope="row">
                                                <div class="d-flex align-items-center justify-content-center text-center rounded-circle mt-2 ms-2"
                                                    style="width: 150px; height: 150px; border: 5px solid #d63384;">
                                                    <p class="mb-0">{{finalResults[5]}}%</p>
                                                </div>
                                            </th>
                                            <td class="table-pink">
                                                <div>
                                                    <p class="mb-0 fw-bold">Convencional</p>
                                                </div>
                                            </td>
                                            <td>Incluye a las personas con un alto grado de control y que prefieren
                                                trabajar con números y cifras. Son precisos en su trabajo y siempre
                                                cumplen las normas, leyes y reglamentos laborales.</td>
                                            <td>Organizar, planificar rigurosamente las actividades, aplicar métodos o
                                                reglas de gestión, controlar la calidad, estructurar y clasificar la
                                                información. </td>
                                            <td>La informática, las reglas y las convenciones, los métodos, la
                                                anticipación de acontecimientos, el cuidado del detalle, la eficacia.
                                            </td>
                                            <td>Informática, gestión de producción, pre-venta, consejo en organización,
                                                control de gestión, finanzas, contabilidad, jurídico. </td>
                                            <td>El cambio inopinado, la improvisación frente a situaciones importantes,
                                                la falta de métodos o medios. </td>                                              
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    -->

          <div class="row mb-4">
            <div class="col">
              <div style="overflow-x: auto" class="border shadow">
                <table class="table table-light results-table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Resultados porcentuales totales</th>
                      <th scope="col">Dimensión</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody *ngIf="finalResults">
                    <tr class="col">
                      <th scope="row">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                            text-center
                            rounded-circle
                            mt-2
                            ms-2
                          "
                          style="
                            width: 150px;
                            height: 150px;
                            border: 5px solid rgb(220, 53, 69);
                          "
                        >
                          <!---->
                          <p class="mb-0">{{ finalResults[0] }}%</p>
                        </div>
                      </th>
                      <td style="background-color: rgba(220, 53, 69, 0.3)">
                        <div>
                          <p class="mb-0 fw-bold">Realista</p>
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          href="../assets/folletos/realista.pdf"
                          target="_blank"
                        >
                          <img
                            width="400px"
                            height="230px"
                            src="../assets/gif_animados/realista.gif"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr class="col">
                      <th scope="row">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                            text-center
                            rounded-circle
                            mt-2
                            ms-2
                          "
                          style="
                            width: 150px;
                            height: 150px;
                            border: 5px solid rgb(32, 201, 151);
                          "
                        >
                          <p class="mb-0">{{ finalResults[1] }}%</p>
                        </div>
                      </th>
                      <td style="background-color: rgba(32, 201, 151, 0.3)">
                        <div>
                          <p class="mb-0 fw-bold">Investigador</p>
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          href="../assets/folletos/investigador.pdf"
                          target="_blank"
                        >
                          <img
                            width="300px"
                            height="230px"
                            src="../assets/gif_animados/investigador.gif"
                          />
                        </a>
                      </td>
                    </tr>

                    <tr class="col">
                      <th scope="row">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                            text-center
                            rounded-circle
                            mt-2
                            ms-2
                          "
                          style="
                            width: 150px;
                            height: 150px;
                            border: 5px solid rgb(201, 151, 198);
                          "
                        >
                          <p class="mb-0">{{ finalResults[2] }}%</p>
                        </div>
                      </th>
                      <td style="background-color: rgba(201, 151, 198, 0.3)">
                        <div>
                          <p class="mb-0 fw-bold">Social</p>
                        </div>
                      </td>
                      <td class="text-center">
                        <a href="../assets/folletos/social.pdf" target="_blank">
                          <img
                            width="250px"
                            height="300px"
                            src="../assets/gif_animados/social.gif"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr class="col">
                      <th scope="row">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                            text-center
                            rounded-circle
                            mt-2
                            ms-2
                          "
                          style="
                            width: 150px;
                            height: 150px;
                            border: 5px solid rgb(255, 193, 7);
                          "
                        >
                          <p class="mb-0">{{ finalResults[3] }}%</p>
                        </div>
                      </th>
                      <td style="background-color: rgb(255, 193, 7, 0.3)">
                        <div>
                          <p class="mb-0 fw-bold">Emprendedor</p>
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          href="../assets/folletos/emprendedor.pdf"
                          target="_blank"
                        >
                          <img
                            width="350px"
                            height="200px"
                            src="../assets/gif_animados/emprendedor.gif"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr class="col">
                      <th scope="row">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                            text-center
                            rounded-circle
                            mt-2
                            ms-2
                          "
                          style="
                            width: 150px;
                            height: 150px;
                            border: 5px solid rgba(247, 150, 70);
                          "
                        >
                          <p class="mb-0">{{ finalResults[4] }}%</p>
                        </div>
                      </th>
                      <td style="background-color: rgba(247, 150, 70, 0.3)">
                        <div>
                          <p class="mb-0 fw-bold">Artístico</p>
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          href="../assets/folletos/artistico.pdf"
                          target="_blank"
                        >
                          <img
                            width="200px"
                            height="250px"
                            src="../assets/gif_animados/artista.gif"
                          />
                        </a>
                      </td>
                    </tr>
                    <tr class="col">
                      <th scope="row">
                        <div
                          class="
                            d-flex
                            align-items-center
                            justify-content-center
                            text-center
                            rounded-circle
                            mt-2
                            ms-2
                          "
                          style="
                            width: 150px;
                            height: 150px;
                            border: 5px solid rgb(13, 202, 240);
                          "
                        >
                          <p class="mb-0">{{ finalResults[5] }}%</p>
                        </div>
                      </th>
                      <td style="background-color: rgb(13, 202, 240, 0.3)">
                        <div>
                          <p class="mb-0 fw-bold">Convencional</p>
                        </div>
                      </td>
                      <td class="text-center">
                        <a
                          href="../assets/folletos/convencional.pdf"
                          target="_blank"
                        >
                          <img
                            width="250px"
                            height="230px"
                            src="../assets/gif_animados/convencional.gif"
                          />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Subtitle -->
          <div
            class="d-sm-flex align-items-center justify-content-between mt-5"
          >
            <h4 style="color: #d06a53 !important">Tu test</h4>
          </div>

          <!-- Explicación prueba -->
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-12">
              <p>
                A continuación, encontrarás los
                <span class="text-orange fw-bold"
                  >resultados <u>porcentuales</u> que obtuviste en cada una de
                  las secciones de la prueba</span
                >. La finalidad de lo anterior, es que puedas entender tus
                resultados detalladamente, ¡vamos!
              </p>
            </div>
            <div class="col-6 mb-5 animation-women mt-2">
              <ng-lottie
                [options]="optionsWomen"
                containerClass="animation-women"
              ></ng-lottie>
            </div>
          </div>

          <!-- Cards Content Row -->
          <!--
                    <div class="row">
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-primary shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Earnings (Monthly)</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">$40,000</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
          
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-success shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Earnings (Annual)</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">$215,000</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

    
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-info text-uppercase mb-1">Tasks
                                            </div>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">50%</div>
                                                </div>
                                                <div class="col">
                                                    <div class="progress progress-sm mr-2">
                                                        <div class="progress-bar bg-info" role="progressbar"
                                                            style="width: 50%" aria-valuenow="50" aria-valuemin="0"
                                                            aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

         
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-left-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                                Pending Requests</div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">18</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fas fa-comments fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->
          <!-- Content Row -->
          <div class="row">
            <!-- Total todo junto -->
            <div class="col-12">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Resultado total del test</h6>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div style="width: 400px; height: 400px">
                    <canvas id="myChartTotal"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Autococimiento Parte A -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold">Autoconocimiento Parte A</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de características que definen tu
                    personalidad.
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart1"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Autococimiento Parte B -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Autoconocimiento Parte B</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de las capacidades que crees tener en
                    relación a los demás.
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart2"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Autococimiento Parte C -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Autoconocimiento Parte C</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado respecto a temas (artes, negocios, ciencia,
                    propósito) que te parecen poco o muy importantes.
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart3"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Autococimiento Parte D -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Autoconocimiento Parte D</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de lo que te gustaría hacer o te sientes muy
                    incompetente.
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart4"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Actividades -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Actividades</h6>
                </div>
                <!-- Card Body -->
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de las acciones concretas que quieres
                    desarrollar en situaciones de tu vida (pintar, vender,
                    reparar, investigar, enseñar).
                  </p>
                </div>
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart5"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Habilidades -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Habilidades</h6>
                </div>
                <!-- Card Body -->
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de aquellas acciones concretas en las que te
                    gustaría capacitarte porque crees que eres bueno. (Usar
                    herramientas, Ayudar a otros, Escribir formulas químicas,
                    liderar equipos de trabajo, Dar un concierto, llevar la
                    contabilidad).
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart6"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Ocupaciones -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Ocupaciones</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de aquello en lo que te ocuparás y le puedes
                    dar el nombre de profesión. (Ingeniero, Psicólogo, Geólogo,
                    Administrador, Actor, Contador).
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart7"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Autoevaluación 1 -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Autoevaluación 1</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de tu reflexión después de pensar lo que te
                    gustaría ser.
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart8"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>

            <!-- Autoevaluación 2 -->
            <div class="col-12 col-lg-6">
              <div class="card shadow mb-4">
                <!-- Card Header - Dropdown -->
                <div
                  class="
                    card-header
                    py-3
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-between
                  "
                >
                  <h6 class="m-0 font-weight-bold">Autoevaluación 2</h6>
                </div>
                <div class="card-body explanation">
                  <p class="pb-0">
                    Es el resultado de tu reflexión después de que crees que
                    estás cerca de tomar una decisión profesional.
                  </p>
                </div>
                <!-- Card Body -->
                <div
                  class="card-body text-center d-flex justify-content-center"
                >
                  <div class="divChart">
                    <canvas id="myChart9"></canvas>
                  </div>
                  <!--
                                    <div id="divChart">
                                        <canvas id="myChart" width = "1000px" height = "400"></canvas>
                                    </div>
                                    -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->

        <!-- Programas Académicos -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 2">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Programas Académicos
            </h1>
          </div>
          <p class="mt-3">
            De acuerdo al perfil que identificamos de ti, con base en los
            resultados del test, nuestro algoritmo determinó que estos son los
            programas académicos que más se acoplan a tu perfil:
          </p>
          <!-- Top programas académicos -->
          <div class="row mt-4 mb-2">
            <div class="col">
              <div style="overflow-x: auto" class="shadow border">
                <table class="table table-striped table-personalized">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span class="text-orange fw-bold"
                          >Top carreras según tu perfil
                        </span>
                      </th>
                      <th scope="col">Programa académico</th>
                      <th scope="col">Universidad</th>
                      <!--
                                            <th scope="col">Ocupaciones</th>
                                            <th scope="col">Habilidades</th>
                                            <th scope="col">Actividades</th>
                                            -->
                      <th scope="col">Dimensiones de la Carrera</th>
                      <th scope="col">Departamento</th>
                      <th scope="col">Ciudad</th>
                      <th scope="col">Tipo de programa</th>
                      <th scope="col">Cantidad de créditos</th>
                      <th scope="col">Valor del semestre</th>
                      <th scope="col">Modalidad</th>
                      <!--
                                            <th scope="col">Enfoque 1</th>
                                            <th scope="col">Enfoque 2</th>
                                            <th scope="col">Enfoque 3</th>
                                            <th scope="col">Enfoque 4</th>
                                            <th scope="col">Enfoque 5</th>
                                            <th scope="col">Enfoque 6</th>
                                            <th scope="col">Enfoque 7</th>
                                            <th scope="col">Enfoque 8</th>
                                             -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let g of predictData?.programas_academicos;
                        let i = index
                      "
                    >
                      <td scope="row">
                        <span
                          class="text-orange fw-bold"
                          style="font-size: 20px"
                          >{{ i + 1 }}
                          <!-- <input
                          class="form-check-input"
                          formControlName="save"
                          type="checkbox"
                        /> -->
                        </span
                        >
                          
                      </td>
                      <td>{{ g.programa_academico }}</td>
                      <td>{{ g.universidad }}</td>
                      <!--
                                            <td class = "text-center">{{ this.ocupaciones_promedio }}%</td>
                                            <td class = "text-center">{{ this.habilidades_promedio }}%</td>
                                            <td class = "text-center">{{ this.actividades_promedio }}%</td>
                                            -->
                      <td scope="row">{{ g.dimensiones }}</td>
                      <td>{{ g.departamento }}</td>
                      <td>{{ g.ciudad }}</td>
                      <td>{{ g.tipo_progama }}</td>
                      <td>{{ g.cantidad_creditos }}</td>
                      <td>
                        <span *ngIf="g.valor_semestre != null">{{
                          this.formatNumber(
                            this.parseInt(g.valor_semestre),
                            "en-US"
                          )
                        }}</span>
                      </td>

                      <td>{{ g.modalidad }}</td>
                      <!--
                                            <td>{{ g.enfoque1 }}</td>
                                            <td>{{ g.enfoque2 }}</td>
                                            <td>{{ g.enfoque3 }}</td>
                                            <td>{{ g.enfoque4 }}</td>
                                            <td>{{ g.enfoque5 }}</td>
                                            <td>{{ g.enfoque6 }}</td>
                                            <td>{{ g.enfoque7 }}</td>
                                            <td>{{ g.enfoque8 }}</td>
                                            -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Buscador programas académicos -->
          <app-searcher-programas></app-searcher-programas>
        </div>

        <!-- Grupos de Investigación -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 3">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Grupos de Investigación
            </h1>
          </div>
          <p class="mt-3">
            De acuerdo al perfil que identificamos de ti, con base en los
            resultados del test, nuestro algoritmo determinó que estos son los
            programas académicos que más se acoplan a tu perfil:
          </p>
          <div class="row mt-4 mb-2">
            <div class="col">
              <div style="overflow-x: auto" class="border shadow">
                <table class="table table-striped table-personalized">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span class="text-orange fw-bold"
                          >Top grupos de investigación según tu perfil</span
                        >
                      </th>
                      <th scope="col">Dimensiones</th>
                      <th scope="col">Universidad</th>
                      <th scope="col">Facultad</th>
                      <th scope="col">Grupo de investigación</th>
                      <th scope="col">Linea de investigación</th>
                      <th scope="col">Departamento</th>
                      <th scope="col">Ciudad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let g of predictData?.grupos_investigacion;
                        let i = index
                      "
                    >
                      <td scope="row">
                        <span
                          class="text-orange fw-bold"
                          style="font-size: 20px"
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>{{ g.dimensiones }}</td>
                      <td>{{ g.universidad }}</td>
                      <td>{{ g.facultad }}</td>
                      <td>{{ g.grupos_investigacion }}</td>
                      <td>{{ g.linea_investigacion }}</td>
                      <td>{{ g.departamento }}</td>
                      <td>{{ g.ciudad }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <!-- Buscador grupos de investigación -->
          <app-searcher-grupos></app-searcher-grupos>
        </div>

        <!-- Objetivos de Desarrollo Sostenible -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 4">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Objetivos de Desarrollo Sostenible
            </h1>
          </div>
          <p class="mt-3">
            De acuerdo a tu perfil profesional, nuestro algoritmo determinó que
            estos son los objetivos de desarrollo sostenible que más se
            relacionan contigo:
          </p>

          <div class="row mt-4 mb-2">
            <div class="col">
              <div style="overflow-x: auto" class="border shadow">
                <table class="table table-striped table-personalized">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span class="text-orange fw-bold"
                          >Top objetivos según tu perfil</span
                        >
                      </th>
                      <th scope="col">Objetivos</th>
                      <th scope="col">Descripción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let g of predictData?.objetivos_desarrollo;
                        let i = index
                      "
                    >
                      <td scope="row">
                        <span
                          class="text-orange fw-bold"
                          style="font-size: 20px"
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td scope="row">
                        <img
                          [src]="mapaObjetivos[g.objetivo]"
                          width="200px;"
                          height="200px;"
                        />
                      </td>
                      <td>{{ g.descripcion }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <app-searcher-objetivos></app-searcher-objetivos>
        </div>

        <!-- Habilidades por Sectores -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 5">
          <!-- Page Heading -->
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Ocupaciones
            </h1>
          </div>
          <p class="mt-3">
            De acuerdo al perfil que identificamos de ti, con base en los
            resultados del test, nuestro algoritmo determinó que estas son las
            ocupaciones que más se acoplan a tu perfil:
          </p>

          <div class="row mt-4 mb-2">
            <div class="col">
              <div style="overflow-x: auto" class="border shadow">
                <table class="table table-striped table-personalized">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span class="text-orange fw-bold"
                          >Top ocupaciones según tu perfil</span
                        >
                      </th>
                      <th scope="col">Nivel de Ocupación</th>
                      <th scope="col">Habilidades Necesarias</th>
                      <th scope="col">Dimensiones</th>
                      <th scope="col">Posibles cargos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let g of predictData?.habilidades_sectores;
                        let i = index
                      "
                    >
                      <td scope="row">
                        <span
                          class="text-orange fw-bold"
                          style="font-size: 20px"
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>{{ g.ocupacion }}</td>
                      <td scope="row">{{ g.habilidad }}</td>
                      <td scope="row">{{ g.dimensiones }}</td>
                      <td scope="row">{{ g.cargo }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Buscador habilidades -->
          <app-searcher-habilidades></app-searcher-habilidades>
        </div>

        <!-- Salarios -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 6">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Salarios
            </h1>
          </div>
          <p class="mt-3">
            De acuerdo al perfil que identificamos de ti, con base en los
            resultados del test, nuestro algoritmo determinó que estos son los
            salarios que obtendrías de acuerdo a tu profesión:
          </p>
          <div class="row mt-4 mb-2">
            <div class="col">
              <div style="overflow-x: auto" class="border shadow">
                <table class="table table-striped table-personalized">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span class="text-orange fw-bold"
                          >Top salarios según tu perfil</span
                        >
                      </th>
                      <th scope="col">Dimensiones</th>
                      <th scope="col">Nivel de ocupaciones</th>
                      <th scope="col">Sector economico</th>
                      <th scope="col">Industrias relacionadas</th>
                      <th scope="col">Cargos</th>
                      <th scope="col">Salarios</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let g of predictData?.salarios; let i = index">
                      <td scope="row">
                        <span
                          class="text-orange fw-bold"
                          style="font-size: 20px"
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>{{ g.dimensiones }}</td>
                      <td>{{ g.nivel }}</td>
                      <td>{{ g.sector }}</td>
                      <!-- <td>{{ g.area }}</td> -->
                      <td>{{ g.industrias }}</td>
                      <td>{{ g.cargo }}</td>
                      <td>${{ this.formatNumber(g.salario, "en-US") }}</td>

                      <!-- <td>
                                                <span *ngIf = "!g.empresa_grande || g.empresa_grande == '' || g.empresa_grande == null"></span>
                                                <span *ngIf = "g.empresa_grande && g.empresa_grande != '' && g.empresa_grande != null">
                                                    <span *ngIf = "!g.empresa_grande.includes('-')">{{ this.formatNumber(this.parseInt(g.empresa_grande),
                                                        "en-US") }}</span>
                                                    <span *ngIf = "g.empresa_grande.includes('-')">{{ g.empresa_grande }}</span>
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf = "!g.empresa_mediana || g.empresa_mediana == '' || g.empresa_mediana == null"></span>
                                                <span *ngIf = "g.empresa_mediana && g.empresa_mediana != '' && g.empresa_mediana != null">
                                                    <span *ngIf = "!g.empresa_mediana.includes('-')">{{ this.formatNumber(this.parseInt(g.empresa_mediana),
                                                        "en-US") }}</span>
                                                    <span *ngIf = "g.empresa_mediana.includes('-')">{{ g.empresa_mediana }}</span>
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf = "!g.empresa_pequena || g.empresa_pequena == '' || g.empresa_pequena == null"></span>
                                                <span *ngIf = "g.empresa_pequena && g.empresa_pequena != '' && g.empresa_pequena != null">
                                                    <span *ngIf = "!g.empresa_pequena.includes('-')">{{ this.formatNumber(this.parseInt(g.empresa_pequena),
                                                        "en-US") }}</span>
                                                    <span *ngIf = "g.empresa_pequena.includes('-')">{{ g.empresa_pequena }}</span>
                                                </span>
                                            </td> -->

                      <!--
                                            <td>
                                                <span *ngIf="g.empresa_grande && g.empresa_grande.includes('-')">{{ g.empresa_grande
                                                    }}</span>
                                                <span
                                                    *ngIf="g.empresa_grande && g.empresa_grande != null && !g.empresa_grande.includes('-')">{{this.formatNumber(this.parseInt(g.empresa_grande),
                                                    "en-US")
                                                    }}</span>

                                            </td>
                                            <td>
                                                <span *ngIf="g.empresa_mediana.includes('-')">{{ g.empresa_mediana
                                                    }}</span>
                                                <span
                                                    *ngIf="g.empresa_mediana != null && !g.empresa_mediana.includes('-')">{{
                                                    this.formatNumber(this.parseInt(g.empresa_mediana), "en-US")
                                                    }}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="g.empresa_pequena.includes('-')">{{ g.empresa_pequena
                                                    }}</span>
                                                <span
                                                    *ngIf="g.empresa_pequena != null && !g.empresa_pequena.includes('-')">{{
                                                    this.formatNumber(this.parseInt(g.empresa_pequena), "en-US")
                                                    }}</span>
                                            </td>
                                            -->
                      <!-- <td>{{ g.dimensiones }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Buscador salarios -->
          <app-searcher-salarios></app-searcher-salarios>
        </div>

        <!-- Sectores económicos y graduados -->
        <div class="container-fluid px-4 py-3" *ngIf="tab == 7">
          <!-- Page Heading -->
          <div
            class="d-sm-flex align-items-center justify-content-between mb-4"
          >
            <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53 !important">
              Sectores económicos y graduados
            </h1>
          </div>
          <p class="mt-3">
            De acuerdo al perfil que identificamos de ti, con base en los
            resultados del test, nuestro algoritmo determinó que estos son los
            sectores económicos más relacionados con tu perfil:
          </p>

          <div class="row mt-4 mb-2">
            <div class="col">
              <div style="overflow-x: auto" class="border shadow">
                <table class="table table-striped table-personalized">
                  <thead>
                    <tr>
                      <th scope="col">
                        <span class="text-orange fw-bold"
                          >Top sectores económicos según tu perfil</span
                        >
                      </th>
                      <th scope="col">Dimensiones</th>
                      <th scope="col">Núcleos de conocimiento</th>
                      <th scope="col">Sectores económicos</th>
                      <th scope="col">Areas de conocimiento</th>
                      <th scope="col">Graduados</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let g of predictData?.sectores_economicos;
                        let i = index
                      "
                    >
                      <td scope="row">
                        <span
                          class="text-orange fw-bold"
                          style="font-size: 20px"
                          >{{ i + 1 }}</span
                        >
                      </td>
                      <td>{{ g.dimensiones }}</td>
                      <td>{{ g.nucleos_de_conocimiento }}</td>
                      <td>{{ g.sectores_economicos }}</td>
                      <td>{{ g.areas }}</td>
                      <td>
                        {{
                          this.formatNumber(this.parseInt(g.graduados), "en-US")
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Buscador sectores económicos y graduados -->
          <app-searcher-sectores></app-searcher-sectores>
        </div>
      </div>
      <!-- End of Main Content -->

      <!-- Footer -->
      <footer class="sticky-footer bg-white py-4">
        <div class="container my-auto">
          <div class="copyright text-center">
            &copy; Copyright 2021 <strong><span>Octaedro</span></strong
            >. Todos los derechos reservados.
          </div>
          <div class="text-center">Versión {{ version }}</div>
        </div>
      </footer>
      <!-- End of Footer -->
    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->

  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>

  <!-- Logout Modal-->
  <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          Select "Logout" below if you are ready to end your current session.
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary" href="login.html">Logout</a>
        </div>
      </div>
    </div>
  </div>
</section>
