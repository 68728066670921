<!--
<div class = "bg-dark text-light">
  <div class="container">
    <nav *ngIf = "envName == 'DEV'" class="navbar navbar-default navbar-fixed ">
      <p class="navbar-brand mb-0 h1 ">Te encuentras en modo desarrollador.</p>
    </nav>
  </div>
</div>
-->

<router-outlet></router-outlet>
