<div class="d-sm-flex align-items-center justify-content-between mt-5">
  <h1 class="h3 mb-0 text-gray-800" style="color: #d06a53">Buscador</h1>
</div>
<!--
<p class="my-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis deserunt corrupti optio placeat
    recusandae sint totam dolor, modi, distinctio eos ipsum consectetur quis tempore nobis earum amet rerum. Illum
    laboriosam eveniet eum eligendi odio asperiores repellendus aspernatur voluptate ab. Rerum dolor hic sed ex
    debitis praesentium nostrum ipsam a unde!</p>
-->

<div class="px-4 pt-2 pb-4 mt-4 bg-white border rounded shadow">
  <!-- Formularios -->
  <section style="padding: 0px" class="mt-3">
    <div class="row">
      <p class="alert alert-danger mb-4" *ngIf="error">
        Ha ocurrido un error. Intente más tarde.
      </p>
      <form class="form" [formGroup]="form">
        <div class="row">
          <!-- Dimensión -->
          <div class="form-group col-12 col-lg-6">
            <h5 class="text-orange">Dimensión</h5>
            <select
              class="form-select"
              formControlName="dimension"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option
                *ngFor="let dim of dimensiones; let i = index"
                [value]="i + 1"
              >
                {{ dim }}
              </option>
            </select>
            <!-- Dimensiones seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let dim of dimensiones_seleccionadas; let i = index"
                  (click)="onRemove('dimensiones', i)"
                >
                  {{ dim }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>

          <!-- Nivel de ocupaciones -->
          <div class="form-group col-12 col-lg-6">
            <h5 class="text-orange">Nivel de ocupaciones</h5>
            <select
              class="form-select"
              formControlName="ocupacion"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option
                *ngFor="let dim of ocupaciones; let i = index"
                [value]="i + 1"
              >
                {{ dim }}
              </option>
            </select>
            <!-- Dimensiones seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let dim of ocupaciones_seleccionadas; let i = index"
                  (click)="onRemove('ocupaciones', i)"
                >
                  {{ dim }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>
          <!-- Posibles cargos -->
          <div class="form-group col-12 col-lg-6">
            <h5 class="text-orange">Posibles cargos</h5>
            <select
              class="form-select"
              formControlName="cargo"
              aria-label="Default select example"
            >
              <option [value]="0" selected></option>
              <option
                *ngFor="let dim of cargos; let i = index"
                [value]="i + 1"
              >
                {{ dim }}
              </option>
            </select>
            <!-- Dimensiones seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let dim of cargos_seleccionadas; let i = index"
                  (click)="onRemove('cargos', i)"
                >
                  {{ dim }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>

          <!-- Habilidades -->
          <div class="form-group col-12 col-lg-6 mt-3 mt-lg-0">
            <h5 class="text-orange">Habilidades necesarias</h5>
            <select
              class="form-select"
              formControlName="habilidad"
              aria-label="Default select example"
            >
              <option selected [value]="0"></option>
              <option
                *ngFor="let hab of habilidades; let i = index"
                [value]="i + 1"
                selected
              >
                {{ hab }}
              </option>
            </select>
            <!-- Universidades seleccionadas -->
            <div class="mt-3">
              <ul class="lista d-flex flex-column">
                <li
                  class="p-2 btn btn-outline-orange mt-1"
                  *ngFor="let hab of habilidades_seleccionadas; let i = index"
                  (click)="onRemove('habilidades', i)"
                >
                  {{ hab }}
                  <i class="bi bi-x-circle"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="alert alert-info mt-2" *ngIf="noResults">
          <p class="m-0">No se encontraron resultados.</p>
        </div>
        <div class="row mt-5 mt-lg-3 d-flex justify-content-end">
          <div class="col-12 col-lg-4" style="text-align: right !important">
            <a class="btn-more m-0 pt-2 me-2" type="submit" (click)="onFilter()"
              >Filtrar</a
            >
            <a class="btn-more m-0 pt-2" (click)="cleanFilters()">Limpiar</a>
          </div>
        </div>
      </form>
    </div>
  </section>
</div>
<div class="row mt-4 mb-2">
  <div class="col">
    <div style="overflow-x: auto" class="border shadow">
      <table class="table table-striped table-personalized bg-white">
        <thead>
          <tr>
            <th scope="col" class="text-orange">Nivel de Ocupación</th>
            <th scope="col" class="text-orange">Habilidades Necesarias</th>
            <th scope="col" class="text-orange">Dimensiones</th>
            <th scope="col" class="text-orange">Posibles cargos</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let g of elements; let i = index">
            <td>{{ g.ocupacion }}</td>
            <td>{{ g.habilidad }}</td>
            <td>{{ g.dimensiones }}</td>
            <td>{{ g.cargo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
